import { t } from "../Localization.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";

export function p(key) {
    return t("sajab.termsAndConditions." + key);
}

export function Body() {
    let value_5, value_7, value_27, value_38, value_40, value_46, value_48, value_54, value_56, value_62, value_73, value_75, value_81, value_92, value_103, value_105, value_111, value_122, value_124, value_130, value_132, value_138, value_140, value_146, value_148, value_154, value_156;
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                paddingTop: 20,
            },
            className: join(" ", ["has-background-white", "has-text-grey-darker"]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_5 = p("subtitle.1"), createElement("h1", {
                        children: [value_5],
                    }))]),
                }), (value_7 = p("text.1"), createElement("p", {
                    children: [value_7],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([createElement("h1", {
                        style: {
                            whiteSpace: "pre-line",
                        },
                        children: p("subtitle.2"),
                    })]),
                }), createElement("p", {
                    style: {
                        whiteSpace: "pre-line",
                    },
                    children: p("text.2"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_27 = p("subtitle.3"), createElement("h1", {
                        children: [value_27],
                    }))]),
                }), createElement("p", {
                    style: {
                        whiteSpace: "pre-line",
                    },
                    children: p("text.3"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_38 = p("subtitle.4"), createElement("h1", {
                        children: [value_38],
                    }))]),
                }), (value_40 = p("text.4"), createElement("p", {
                    children: [value_40],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_46 = p("subtitle.5"), createElement("h1", {
                        children: [value_46],
                    }))]),
                }), (value_48 = p("text.5"), createElement("p", {
                    children: [value_48],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_54 = p("subtitle.6"), createElement("h1", {
                        children: [value_54],
                    }))]),
                }), (value_56 = p("text.6"), createElement("p", {
                    children: [value_56],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_62 = p("subtitle.7"), createElement("h1", {
                        children: [value_62],
                    }))]),
                }), createElement("p", {
                    style: {
                        whiteSpace: "pre-line",
                    },
                    children: p("text.7"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_73 = p("subtitle.8"), createElement("h1", {
                        children: [value_73],
                    }))]),
                }), (value_75 = p("text.8"), createElement("p", {
                    children: [value_75],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_81 = p("subtitle.9"), createElement("h1", {
                        children: [value_81],
                    }))]),
                }), createElement("p", {
                    style: {
                        whiteSpace: "pre-line",
                    },
                    children: p("text.9"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_92 = p("subtitle.10"), createElement("h1", {
                        children: [value_92],
                    }))]),
                }), createElement("p", {
                    style: {
                        whiteSpace: "pre-line",
                    },
                    children: p("text.10"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_103 = p("subtitle.11"), createElement("h1", {
                        children: [value_103],
                    }))]),
                }), (value_105 = p("text.11"), createElement("p", {
                    children: [value_105],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_111 = p("subtitle.12"), createElement("h1", {
                        children: [value_111],
                    }))]),
                }), createElement("p", {
                    style: {
                        whiteSpace: "pre-line",
                    },
                    children: p("text.12"),
                })]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_122 = p("subtitle.13"), createElement("h1", {
                        children: [value_122],
                    }))]),
                }), (value_124 = p("text.13"), createElement("p", {
                    children: [value_124],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_130 = p("subtitle.14"), createElement("h1", {
                        children: [value_130],
                    }))]),
                }), (value_132 = p("text.14"), createElement("p", {
                    children: [value_132],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_138 = p("subtitle.15"), createElement("h1", {
                        children: [value_138],
                    }))]),
                }), (value_140 = p("text.15"), createElement("p", {
                    children: [value_140],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_146 = p("subtitle.16"), createElement("h1", {
                        children: [value_146],
                    }))]),
                }), (value_148 = p("text.16"), createElement("p", {
                    children: [value_148],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([(value_154 = p("subtitle.17"), createElement("h1", {
                        children: [value_154],
                    }))]),
                }), (value_156 = p("text.17"), createElement("p", {
                    children: [value_156],
                }))]),
            }), createElement("div", {
                style: {
                    marginBottom: 10,
                },
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["is-family-secondary", "termsSection"]),
                    children: Interop_reactApi.Children.toArray([createElement("h1", {
                        style: {
                            whiteSpace: "pre-line",
                        },
                        children: p("subtitle.18"),
                    })]),
                }), createElement("p", {
                    style: {
                        whiteSpace: "pre-line",
                    },
                    children: p("text.18"),
                })]),
            })]),
        })]),
    });
}

export function Render() {
    return createElement("div", {
        style: {
            padding: ((((((60 + "px ") + 60) + "px ") + 120) + "px ") + 60) + "px",
        },
        children: Interop_reactApi.Children.toArray([createElement(Body, null)]),
    });
}

