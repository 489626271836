import { getSupportedLanguages, setCurrentLanguage, getCurrentLanguage, t } from "./Localization.js";
import { Union, Record } from "./fable_modules/fable-library.3.6.1/Types.js";
import { record_type, list_type, class_type, union_type, int32_type } from "./fable_modules/fable-library.3.6.1/Reflection.js";
import { Orders_LotsInOrder, Errors_ServerError, Auth_LoggedInUser$reflection, Orders_LotsInOrder$reflection, Errors_ServerError$reflection } from "./Shared/Shared.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.6.1/Choice.js";
import { Remoting_subscribeOrdersChangedEvent, Remoting_subscribeBidsChangedEvent, Remoting_subscribeFavouritesChangedEvent, Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, Remoting_handleNonAuth, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "./Extensions.js";
import { singleton } from "./fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { homeApi, ordersApi, lotsAuthApi } from "./Communication.js";
import { length, filter, singleton as singleton_1, append, map, ofArray } from "./fable_modules/fable-library.3.6.1/List.js";
import { map as map_1, concat } from "./fable_modules/fable-library.3.6.1/Array.js";
import { removeUser } from "./LocalStorage.js";
import { Cmd_none, Cmd_OfFunc_result, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { List_distinct } from "./fable_modules/fable-library.3.6.1/Seq2.js";
import { equals } from "./fable_modules/fable-library.3.6.1/Long.js";
import { createObj, arrayHash, equalArrays, fastStructuralHash } from "./fable_modules/fable-library.3.6.1/Util.js";
import { LangModule_toString, LangModule_swedish, LangModule_english, LangModule_$007CSwedish$007CEnglish$007CFinnish$007C } from "./bidflow/src/infrastructure/Localization.js";
import { Img_originalImageUrlInPng, Img_flagSweden, Img_flagFinland, Img_flagBritain } from "./Css.js";
import { useReact_useEffectOnce_3A5B6456, React_createDisposable_3A5B6456, useReact_useCallbackRef_7C4B0DD6, useReact_useEffect_Z5ECA432F, useReact_useMemo_CF4EA67, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.1.45.0/React.fs.js";
import { createElement } from "react";
import { join } from "./fable_modules/fable-library.3.6.1/String.js";
import { Interop_reactApi } from "./fable_modules/Feliz.1.45.0/Interop.fs.js";
import { map as map_2, empty, singleton as singleton_2, append as append_1, delay, toList } from "./fable_modules/fable-library.3.6.1/Seq.js";
import { Helpers_combineClasses } from "./fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useVisible } from "react-hooks-visible";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "./fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { ImageUrlModule_toString } from "./bidflow/src/infrastructure/Auction.js";
import { CustomerSpecific, Route, toPath } from "./Router.js";
import { defaultArg } from "./fable_modules/fable-library.3.6.1/Option.js";
import { Impl_createRemoveOptions, Impl_adjustPassive, Impl_defaultPassive } from "./fable_modules/Feliz.UseListener.0.6.3/Listener.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "./fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { Url_getCurrentUrlPage, MediaQuery_customBreakpoints } from "./Common.js";
import { scrollableAnchor } from "./Components/ScrollableAnchor.js";
import { useFeliz_React__React_useElmish_Static_17DC4F1D } from "./fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";

export function localize(key) {
    return t("header." + key);
}

export class Types_State extends Record {
    constructor(numberOfLiked, numberOfBids, orders, User) {
        super();
        this.numberOfLiked = numberOfLiked;
        this.numberOfBids = numberOfBids;
        this.orders = orders;
        this.User = User;
    }
}

export function Types_State$reflection() {
    return record_type("Header.Types.State", [], Types_State, () => [["numberOfLiked", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [int32_type, Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", int32_type]], [["ErrorValue", Errors_ServerError$reflection()]]]))], ["numberOfBids", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(class_type("System.Int64")), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(class_type("System.Int64"))]], [["ErrorValue", Errors_ServerError$reflection()]]]))], ["orders", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Orders_LotsInOrder$reflection(), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", Orders_LotsInOrder$reflection()]], [["ErrorValue", Errors_ServerError$reflection()]]]))], ["User", Auth_LoggedInUser$reflection()]]);
}

export class Types_Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LogOut", "FavouritesLoad", "FavouritesChanged", "BidsChanged", "BidsLoad", "StartSubscription", "OrdersLoad", "OrdersChanged"];
    }
}

export function Types_Msg$reflection() {
    return union_type("Header.Types.Msg", [], Types_Msg, () => [[], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [int32_type, Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", int32_type]], [["ErrorValue", Errors_ServerError$reflection()]]]))]], [["Item", int32_type]], [["Item", class_type("System.Int64")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(class_type("System.Int64")), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(class_type("System.Int64"))]], [["ErrorValue", Errors_ServerError$reflection()]]]))]], [], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [Orders_LotsInOrder$reflection(), Errors_ServerError$reflection()], FSharpResult$2, () => [[["ResultValue", Orders_LotsInOrder$reflection()]], [["ErrorValue", Errors_ServerError$reflection()]]]))]], [["Item1", class_type("System.Int64")], ["Item2", class_type("System.Int64")]]]);
}

export function Cmd_getNumberOfLiked() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().getLikedLotsForHeader()), (_arg1) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1.length)))))), (_arg2) => singleton.Return(new Types_Msg(1, new AsyncOperationStatus$1(1, new FSharpResult$2(1, new Errors_ServerError(0, _arg2.message)))))));
}

export function Cmd_getNumberOfBids() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(lotsAuthApi().getBidsLotsForHeader()), (_arg1) => singleton.Return(new Types_Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(0, _arg1)))))), (_arg2) => singleton.Return(new Types_Msg(4, new AsyncOperationStatus$1(1, new FSharpResult$2(1, new Errors_ServerError(0, _arg2.message)))))));
}

export function Cmd_getNumberOfOrders() {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(Remoting_handleNonAuth(ordersApi().getOrdersForHeader()), (_arg1) => {
        const number = _arg1;
        const toStructure = (o) => ofArray(concat(map((tupledArg) => map_1((id) => [tupledArg[2], id], tupledArg[1]), o)));
        return singleton.Return(new Types_Msg(6, new AsyncOperationStatus$1(1, new FSharpResult$2(0, new Orders_LotsInOrder(toStructure(number.UnpaidOrdersInPreviousAndInCurrentAuctions), toStructure(number.AllOrdersInCurrentAuction))))));
    })), (_arg2) => singleton.Return(new Types_Msg(6, new AsyncOperationStatus$1(1, new FSharpResult$2(1, new Errors_ServerError(0, _arg2.message)))))));
}

export function Cmd_removeUser() {
    removeUser();
}

export function State_init(user) {
    return [new Types_State(new Deferred$1(0), new Deferred$1(0), new Deferred$1(0), user), Cmd_batch(ofArray([Cmd_OfFunc_result(new Types_Msg(1, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Types_Msg(4, new AsyncOperationStatus$1(0))), Cmd_OfFunc_result(new Types_Msg(6, new AsyncOperationStatus$1(0)))]))];
}

export function State_update(logOut, msg, state) {
    let matchValue, matchValue_1, matchValue_2, lotIdsInCurrentAuction;
    if (msg.tag === 0) {
        Cmd_removeUser();
        logOut();
        return [state, Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [new Types_State((matchValue = state.numberOfLiked, (matchValue.tag === 1) ? (new Deferred$1(1)) : ((matchValue.tag === 2) ? ((matchValue.fields[0].tag === 1) ? (new Deferred$1(2, new FSharpResult$2(1, matchValue.fields[0].fields[0]))) : (new Deferred$1(2, new FSharpResult$2(0, matchValue.fields[0].fields[0] + msg.fields[0])))) : (new Deferred$1(0)))), state.numberOfBids, state.orders, state.User), Cmd_none()];
    }
    else if (msg.tag === 1) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                return [new Types_State(new Deferred$1(2, new FSharpResult$2(1, msg.fields[0].fields[0].fields[0])), state.numberOfBids, state.orders, state.User), Cmd_none()];
            }
            else {
                return [new Types_State(new Deferred$1(2, new FSharpResult$2(0, msg.fields[0].fields[0].fields[0])), state.numberOfBids, state.orders, state.User), Cmd_none()];
            }
        }
        else {
            return [state, Cmd_fromAsync(Cmd_getNumberOfLiked())];
        }
    }
    else if (msg.tag === 3) {
        return [new Types_State(state.numberOfLiked, (matchValue_1 = state.numberOfBids, (matchValue_1.tag === 1) ? (new Deferred$1(1)) : ((matchValue_1.tag === 2) ? ((matchValue_1.fields[0].tag === 1) ? (new Deferred$1(2, new FSharpResult$2(1, matchValue_1.fields[0].fields[0]))) : (new Deferred$1(2, new FSharpResult$2(0, List_distinct(append(singleton_1(msg.fields[0]), matchValue_1.fields[0].fields[0]), {
            Equals: (x_2, y) => equals(x_2, y),
            GetHashCode: (x_2) => fastStructuralHash(x_2),
        }))))) : (new Deferred$1(0)))), state.orders, state.User), Cmd_none()];
    }
    else if (msg.tag === 4) {
        if (msg.fields[0].tag === 1) {
            return [new Types_State(state.numberOfLiked, new Deferred$1(2, msg.fields[0].fields[0]), state.orders, state.User), Cmd_none()];
        }
        else {
            return [state, Cmd_fromAsync(Cmd_getNumberOfBids())];
        }
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 1) {
            return [new Types_State(state.numberOfLiked, state.numberOfBids, new Deferred$1(2, msg.fields[0].fields[0]), state.User), Cmd_none()];
        }
        else {
            return [state, Cmd_fromAsync(Cmd_getNumberOfOrders())];
        }
    }
    else if (msg.tag === 7) {
        return [new Types_State(state.numberOfLiked, state.numberOfBids, (matchValue_2 = state.orders, (matchValue_2.tag === 1) ? (new Deferred$1(1)) : ((matchValue_2.tag === 2) ? ((matchValue_2.fields[0].tag === 1) ? (new Deferred$1(2, new FSharpResult$2(1, matchValue_2.fields[0].fields[0]))) : ((lotIdsInCurrentAuction = List_distinct(append(singleton_1([msg.fields[0], msg.fields[1]]), filter((tupledArg_1) => equals(tupledArg_1[0], msg.fields[0]), matchValue_2.fields[0].fields[0].AllOrdersInCurrentAuction)), {
            Equals: (x_5, y_1) => equalArrays(x_5, y_1),
            GetHashCode: (x_5) => arrayHash(x_5),
        }), new Deferred$1(2, new FSharpResult$2(0, new Orders_LotsInOrder(List_distinct(append(lotIdsInCurrentAuction, matchValue_2.fields[0].fields[0].NumberOfLotsInUnpaidOrders), {
            Equals: (x_6, y_2) => equalArrays(x_6, y_2),
            GetHashCode: (x_6) => arrayHash(x_6),
        }), lotIdsInCurrentAuction)))))) : (new Deferred$1(0)))), state.User), Cmd_none()];
    }
    else {
        return [state, Cmd_batch(ofArray([Remoting_subscribeFavouritesChangedEvent((arg0) => (new Types_Msg(2, arg0))), Remoting_subscribeBidsChangedEvent((arg0_1) => (new Types_Msg(3, arg0_1))), Remoting_subscribeOrdersChangedEvent((tupledArg) => (new Types_Msg(7, tupledArg[0], tupledArg[1])))]))];
    }
}

export function View_getFlag(_arg1) {
    const activePatternResult23860 = LangModule_$007CSwedish$007CEnglish$007CFinnish$007C(_arg1);
    if (activePatternResult23860.tag === 1) {
        return Img_flagBritain;
    }
    else if (activePatternResult23860.tag === 2) {
        return Img_flagFinland;
    }
    else {
        return Img_flagSweden;
    }
}

export function View_toggleLanguage() {
    const lang = getCurrentLanguage();
    setCurrentLanguage((LangModule_$007CSwedish$007CEnglish$007CFinnish$007C(lang).tag === 0) ? LangModule_english : LangModule_swedish);
    document.location.reload();
}

export function View_SwitchLanguage() {
    let props_11;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setShowLang = patternInput[1];
    const isShowLang = patternInput[0];
    return createElement("div", {
        className: join(" ", ["hover-for-header", "languagePadding"]),
        onMouseLeave: (_arg1) => {
            setShowLang(false);
        },
        onClick: (_arg2) => {
            setShowLang(!isShowLang);
        },
        children: Interop_reactApi.Children.toArray([(props_11 = toList(delay(() => append_1(singleton_2(["className", "mr-3"]), delay(() => append_1(singleton_2(["className", "has-dropdown"]), delay(() => append_1(singleton_2(["className", join(" ", ["languageNavBarItem"])]), delay(() => append_1(isShowLang ? singleton_2(["className", "is-active"]) : empty(), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_2;
            return append_1(singleton_2(createElement("a", {
                className: join(" ", ["languageButton"]),
                children: Interop_reactApi.Children.toArray([createElement("img", {
                    src: View_getFlag(getCurrentLanguage()),
                    style: {
                        verticalAlign: "middle",
                        border: (((1 + "px ") + "solid") + " ") + "#000000",
                    },
                }), (props_2 = ofArray([["style", {
                    verticalAlign: "middle",
                    paddingLeft: 10,
                }], ["children", Interop_reactApi.Children.toArray([createElement("i", {
                    style: {
                        marginLeft: 5,
                    },
                    className: join(" ", toList(delay(() => append_1(singleton_2("fa"), delay(() => (isShowLang ? singleton_2("fa-chevron-up") : singleton_2("fa-chevron-down"))))))),
                })])]]), createElement("span", createObj(Helpers_combineClasses("icon", props_2))))]),
            })), delay(() => {
                let elms;
                return isShowLang ? singleton_2((elms = toList(delay(() => map_2((lang_1) => {
                    let props_6;
                    const props_8 = ofArray([["style", {
                        display: "flex",
                        zIndex: 1000,
                    }], ["className", "languageNavbatDropDownItem"], ["onClick", (_arg3) => {
                        setShowLang(false);
                        setCurrentLanguage(lang_1);
                        document.location.reload();
                    }], ["children", Interop_reactApi.Children.toArray([createElement("img", {
                        src: View_getFlag(lang_1),
                        style: {
                            alignSelf: "center",
                            border: (((1 + "px ") + "solid") + " ") + "#000000",
                        },
                    }), (props_6 = ofArray([["className", "is-uppercase"], ["style", {
                        paddingLeft: 10,
                    }], ["children", LangModule_toString(lang_1)]]), createElement("div", createObj(Helpers_combineClasses("", props_6))))])]]);
                    return createElement("a", createObj(Helpers_combineClasses("navbar-item", props_8)));
                }, filter((x_1) => (x_1 !== getCurrentLanguage()), getSupportedLanguages())))), createElement("div", {
                    className: "navbar-dropdown",
                    children: Interop_reactApi.Children.toArray(Array.from(elms)),
                }))) : empty();
            }));
        }))))]))))))))))), createElement("div", createObj(Helpers_combineClasses("navbar-item", props_11))))]),
    });
}

export function View_Header(view_HeaderInputProps) {
    const isShowShowAnnouncementBanner = view_HeaderInputProps.isShowShowAnnouncementBanner;
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setActive = patternInput[1];
    const isActive = patternInput[0];
    const patternInput_1 = useVisible();
    const navRef = patternInput_1[0];
    const patternInput_2 = useVisible();
    const isShown = patternInput_2[1];
    const hide = (_arg1) => {
        setActive(false);
    };
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getCompanyInfo(), []);
    const logo = (settings.tag === 1) ? null : ((settings.tag === 3) ? null : ((settings.tag === 2) ? createElement("img", {
        src: ImageUrlModule_toString(Img_originalImageUrlInPng(settings.fields[0].Logo)),
    }) : null));
    const headerItem = (route, name_2) => createElement("div", {
        className: join(" ", ["hover-for-header", "languagePadding"]),
        children: Interop_reactApi.Children.toArray([createElement("a", {
            className: join(" ", ["navbar-item", "header-mobile-style", "add_cursor_pointer"]),
            children: name_2,
            href: toPath(route),
            onClick: (_arg1_2) => {
                hide();
            },
        })]),
    });
    const elemRef = navRef;
    const callback = hide;
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = matchValue, copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            document.addEventListener(eventType, fn, addOptions);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                document.removeEventListener(eventType, fn, removeOptions);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = matchValue_1, copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_7 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_7), [options_7]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_7), [options_7]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            document.addEventListener(eventType_1, fn_1, addOptions_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                document.removeEventListener(eventType_1, fn_1, removeOptions_1);
            }
        });
    }));
    const minHeight = 58;
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    const items = createElement("div", {
        className: join(" ", toList(delay(() => append_1(singleton_2("navbar-menu"), delay(() => ((width.tag === 0) ? singleton_2("is-active") : ((width.tag === 1) ? singleton_2("is-active") : (isActive ? singleton_2("is-active") : empty())))))))),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: join(" ", ["navbar-end"]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["hover-for-header", "languagePadding"]),
                children: Interop_reactApi.Children.toArray([createElement("a", {
                    className: join(" ", ["navbar-item", "header-mobile-style", "add_cursor_pointer"]),
                    children: localize("menu.auctions"),
                    href: toPath(new Route(1)),
                    onClick: (_arg2) => {
                        hide();
                    },
                })]),
            }), headerItem(new Route(24, new CustomerSpecific(0)), localize("menu.buy")), headerItem(new Route(24, new CustomerSpecific(2)), t("sajab.menu.agriculture")), headerItem(new Route(24, new CustomerSpecific(4)), t("sajab.menu.multisellerAuction")), headerItem(new Route(24, new CustomerSpecific(1)), t("sajab.menu.aboutUs")), createElement("div", {
                className: join(" ", ["hover-for-header", "languagePadding"]),
                children: Interop_reactApi.Children.toArray([createElement("a", {
                    className: join(" ", ["navbar-item", "add_cursor_pointer"]),
                    href: toPath(new Route(6, Url_getCurrentUrlPage())),
                    onClick: (_arg3) => {
                        hide();
                    },
                    style: createObj(toList(delay(() => ((isShown !== 0) ? singleton_2(["display", "none"]) : empty())))),
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fa", "fa-user", "icon-in-circle"]),
                    }), createElement("p", {
                        className: "navbar-item",
                        children: localize("menu.login"),
                        style: {
                            padding: 0,
                        },
                    })]),
                })]),
            }), createElement("div", {
                className: join(" ", ["hover-for-header", "languagePadding"]),
                children: Interop_reactApi.Children.toArray([createElement("a", {
                    className: join(" ", ["navbar-item", "add_cursor_pointer"]),
                    href: toPath(new Route(6, Url_getCurrentUrlPage())),
                    onClick: (_arg4) => {
                        hide();
                    },
                    style: createObj(toList(delay(() => append_1(singleton_2(["display", "flex"]), delay(() => append_1((isShown === 0) ? singleton_2(["display", "none"]) : empty(), delay(() => append_1(singleton_2(["borderBottom", (((1 + "px ") + "solid") + " ") + "#3C3C3C"]), delay(() => append_1(singleton_2(["fontFamily", "\u0027Lato\u0027, Calibri, Arial, sans-serif"]), delay(() => append_1(singleton_2(["fontWeight", 400]), delay(() => append_1(singleton_2(["fontSize", 15 + "px"]), delay(() => singleton_2(["backgroundColor", "#303333"])))))))))))))))),
                    children: Interop_reactApi.Children.toArray([createElement("i", {
                        className: join(" ", ["fa", "fa-user", "fa-xs"]),
                        style: {
                            alignSelf: "center",
                            paddingRight: 15,
                        },
                    }), createElement("p", {
                        className: "navbar-item",
                        children: localize("menu.login"),
                        style: {
                            padding: 0,
                        },
                    })]),
                })]),
            }), createElement(View_SwitchLanguage, null)]),
        })]),
    });
    return createElement("div", createObj(toList(delay(() => append_1(singleton_2(["className", join(" ", ["header-shadow"])]), delay(() => append_1(isShowShowAnnouncementBanner ? singleton_2(["style", {
        top: 30,
    }]) : empty(), delay(() => append_1(singleton_2(["ref", navRef]), delay(() => singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", {
        className: join(" ", ["navbar-fixed-nav", "headerHeightNonAuthorized"]),
        children: Interop_reactApi.Children.toArray([createElement("nav", {
            ref: navRef,
            className: join(" ", ["navbar", "is-family-secondary", "header-nonauthorized", "headerPrimaryColor"]),
            style: createObj(toList(delay(() => ((isShown !== 0) ? singleton_2(["minHeight", minHeight]) : empty())))),
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(Interop_reactApi.createElement(scrollableAnchor, {
                className: join(" ", ["backtotop", "fas", "fa-chevron-up", "is-medium"]),
                href: "#top",
                style: createObj(toList(delay(() => ((patternInput_1[1] !== 0) ? singleton_2(["display", "none"]) : empty())))),
            })), delay(() => append_1(singleton_2(createElement("div", {
                className: join(" ", ["navbar-brand"]),
                style: createObj(toList(delay(() => append_1(singleton_2(["alignItems", "center"]), delay(() => ((isShown !== 0) ? singleton_2(["minHeight", minHeight]) : empty())))))),
                children: Interop_reactApi.Children.toArray([createElement("a", {
                    className: join(" ", ["navbar-item", "navbar-item-logo", "add_cursor_pointer"]),
                    href: toPath(new Route(0)),
                    onClick: (_arg5) => {
                        hide();
                    },
                    children: Interop_reactApi.Children.toArray([logo]),
                }), createElement("div", {
                    ref: patternInput_2[0],
                    className: join(" ", ["navbar-burger", "navbarBurgerCustom"]),
                    onClick: (_arg6) => {
                        setActive(!isActive);
                    },
                    style: createObj(toList(delay(() => append_1((isShown !== 0) ? singleton_2(["height", minHeight]) : empty(), delay(() => append_1(singleton_2(["color", "#fff"]), delay(() => singleton_2(["cursor", "none"])))))))),
                    ["aria-label"]: "menu",
                    ["aria-expanded"]: false,
                    children: Interop_reactApi.Children.toArray([createElement("span", {
                        ["aria-hidden"]: true,
                    }), createElement("span", {
                        ["aria-hidden"]: true,
                    }), createElement("span", {
                        ["aria-hidden"]: true,
                    })]),
                })]),
            })), delay(() => ((width.tag === 0) ? singleton_2(createElement("div", {
                className: join(" ", ["collapse"]),
                style: createObj(toList(delay(() => (isActive ? singleton_2(["pointerEvents", "auto"]) : singleton_2(["pointerEvents", "none"]))))),
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", toList(delay(() => append_1(singleton_2("collapse__panel"), delay(() => append_1((!isActive) ? singleton_2("collapse__panel--down") : singleton_2("collapse__panel--up"), delay(() => singleton_2("burgerText")))))))),
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(items), delay(() => ((width.tag === 0) ? singleton_2(createElement("div", {
                        style: {
                            backgroundColor: "#ffffff",
                            height: 2,
                        },
                    })) : ((width.tag === 1) ? singleton_2(createElement("div", {
                        style: {
                            backgroundColor: "#ffffff",
                            height: 2,
                        },
                    })) : singleton_2(null))))))))),
                })]),
            })) : ((width.tag === 1) ? singleton_2(createElement("div", {
                className: join(" ", ["collapse"]),
                style: createObj(toList(delay(() => (isActive ? singleton_2(["pointerEvents", "auto"]) : singleton_2(["pointerEvents", "none"]))))),
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", toList(delay(() => append_1(singleton_2("collapse__panel"), delay(() => append_1((!isActive) ? singleton_2("collapse__panel--down") : singleton_2("collapse__panel--up"), delay(() => singleton_2("burgerText")))))))),
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(items), delay(() => ((width.tag === 0) ? singleton_2(createElement("div", {
                        style: {
                            backgroundColor: "#ffffff",
                            height: 2,
                        },
                    })) : ((width.tag === 1) ? singleton_2(createElement("div", {
                        style: {
                            backgroundColor: "#ffffff",
                            height: 2,
                        },
                    })) : singleton_2(null))))))))),
                })]),
            })) : singleton_2(items))))))))))),
        })]),
    })])])))))))))));
}

export function View_HeaderAuthorized(view_HeaderAuthorizedInputProps) {
    const isShowShowAnnouncementBanner = view_HeaderAuthorizedInputProps.isShowShowAnnouncementBanner;
    const user = view_HeaderAuthorizedInputProps.user;
    const logOut = view_HeaderAuthorizedInputProps.logOut;
    const patternInput = useFeliz_React__React_useElmish_Static_17DC4F1D(State_init(user), (msg, state) => State_update(logOut, msg, state), [user]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setActive = patternInput_1[1];
    const isActive = patternInput_1[0];
    const patternInput_2 = useVisible();
    const navRef = patternInput_2[0];
    const hide = (_arg1) => {
        setActive(false);
    };
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getCompanyInfo(), []);
    const logo = (settings.tag === 1) ? null : ((settings.tag === 3) ? null : ((settings.tag === 2) ? createElement("img", {
        src: ImageUrlModule_toString(Img_originalImageUrlInPng(settings.fields[0].Logo)),
    }) : null));
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF(MediaQuery_customBreakpoints);
    const elemRef = navRef;
    const callback = hide;
    const options_1 = defaultArg(void 0, Impl_defaultPassive);
    const eventType = "mousedown";
    const action_1 = (ev) => {
        let copyOfStruct;
        const matchValue = elemRef.current;
        let pattern_matching_result;
        if (matchValue != null) {
            if (!((copyOfStruct = matchValue, copyOfStruct.contains(ev.target)))) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                callback(ev);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_3 = options_1;
    const addOptions = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_3), [options_3]);
    const removeOptions = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_3), [options_3]);
    const fn = useReact_useMemo_CF4EA67(() => ((arg) => {
        action_1(arg);
    }), [action_1]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions == null) {
            document.addEventListener(eventType, fn);
        }
        else {
            document.addEventListener(eventType, fn, addOptions);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions == null) {
                document.removeEventListener(eventType, fn);
            }
            else {
                document.removeEventListener(eventType, fn, removeOptions);
            }
        });
    }));
    const eventType_1 = "touchstart";
    const action_3 = (ev_1) => {
        let copyOfStruct_1;
        const matchValue_1 = elemRef.current;
        let pattern_matching_result_1;
        if (matchValue_1 != null) {
            if (!((copyOfStruct_1 = matchValue_1, copyOfStruct_1.contains(ev_1.target)))) {
                pattern_matching_result_1 = 0;
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                callback(ev_1);
                break;
            }
            case 1: {
                break;
            }
        }
    };
    const options_7 = options_1;
    const addOptions_1 = useReact_useMemo_CF4EA67(() => Impl_adjustPassive(options_7), [options_7]);
    const removeOptions_1 = useReact_useMemo_CF4EA67(() => Impl_createRemoveOptions(options_7), [options_7]);
    const fn_1 = useReact_useMemo_CF4EA67(() => ((arg_1) => {
        action_3(arg_1);
    }), [action_3]);
    useReact_useEffect_Z5ECA432F(useReact_useCallbackRef_7C4B0DD6(() => {
        if (addOptions_1 == null) {
            document.addEventListener(eventType_1, fn_1);
        }
        else {
            document.addEventListener(eventType_1, fn_1, addOptions_1);
        }
        return React_createDisposable_3A5B6456(() => {
            if (removeOptions_1 == null) {
                document.removeEventListener(eventType_1, fn_1);
            }
            else {
                document.removeEventListener(eventType_1, fn_1, removeOptions_1);
            }
        });
    }));
    useReact_useEffectOnce_3A5B6456(() => {
        dispatch(new Types_Msg(5));
    });
    const headerItem = (route, name_2) => createElement("div", {
        className: join(" ", ["hover-for-header", "languagePadding"]),
        children: Interop_reactApi.Children.toArray([createElement("a", {
            className: join(" ", ["navbar-item", "header-mobile-style", "add_cursor_pointer"]),
            children: name_2,
            href: toPath(route),
            onClick: (_arg1_2) => {
                hide();
            },
        })]),
    });
    const items = createElement("div", {
        className: join(" ", toList(delay(() => append_1(singleton_2("navbar-menu"), delay(() => {
            switch (width.tag) {
                case 0:
                case 1: {
                    return singleton_2("is-active");
                }
                default: {
                    return empty();
                }
            }
        }))))),
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "navbar-end",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["hover-for-header", "languagePadding"]),
                children: Interop_reactApi.Children.toArray([createElement("a", {
                    className: join(" ", ["navbar-item", "header-mobile-style", "add_cursor_pointer"]),
                    children: localize("menu.auctions"),
                    href: toPath(new Route(1)),
                    onClick: (_arg2) => {
                        hide();
                    },
                })]),
            }), headerItem(new Route(24, new CustomerSpecific(0)), localize("menu.buy")), headerItem(new Route(24, new CustomerSpecific(2)), t("sajab.menu.agriculture")), headerItem(new Route(24, new CustomerSpecific(4)), t("sajab.menu.multisellerAuction")), headerItem(new Route(24, new CustomerSpecific(1)), t("sajab.menu.aboutUs")), createElement("div", {
                className: join(" ", ["navbar-item", "is-hidden-mobile", "hover-for-header", "add_cursor_pointer"]),
                onClick: (x_1) => {
                    x_1.preventDefault();
                    dispatch(new Types_Msg(0));
                    hide();
                },
                children: Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fa", "fa-lock", "icon-in-circle"]),
                }), createElement("div", {
                    className: "navbar-item",
                    children: localize("menu.logout"),
                    style: {
                        padding: 0,
                    },
                })]),
            }), createElement("a", {
                className: join(" ", ["navbar-item", "is-hidden-tablet", "add_cursor_pointer"]),
                href: toPath(new Route(11)),
                onClick: (_arg3) => {
                    hide();
                },
                style: {
                    display: "flex",
                    borderBottom: (((1 + "px ") + "solid") + " ") + "#3C3C3C",
                    fontFamily: "\u0027Lato\u0027, Calibri, Arial, sans-serif",
                    fontWeight: 400,
                    fontSize: 15 + "px",
                    backgroundColor: "#303333",
                },
                children: Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fa", "fa-user", "fa-xs"]),
                    style: {
                        alignSelf: "center",
                        padding: ((0 + "px ") + 15) + "px",
                    },
                }), createElement("div", {
                    className: "navbar-item",
                    children: localize("my.page"),
                    style: {
                        padding: ((0 + "px ") + 5) + "px",
                    },
                })]),
            }), createElement("div", {
                className: join(" ", ["navbar-item", "is-hidden-tablet", "add_cursor_pointer"]),
                onClick: (x_2) => {
                    x_2.preventDefault();
                    dispatch(new Types_Msg(0));
                    hide();
                },
                style: {
                    display: "flex",
                    borderBottom: (((1 + "px ") + "solid") + " ") + "#3C3C3C",
                    fontFamily: "\u0027Lato\u0027, Calibri, Arial, sans-serif",
                    fontWeight: 400,
                    fontSize: 15 + "px",
                    backgroundColor: "#303333",
                },
                children: Interop_reactApi.Children.toArray([createElement("i", {
                    className: join(" ", ["fa", "fa-lock", "fa-xs"]),
                    style: {
                        alignSelf: "center",
                        padding: ((0 + "px ") + 15) + "px",
                    },
                }), createElement("div", {
                    className: "navbar-item",
                    children: localize("menu.logout"),
                    style: {
                        padding: ((0 + "px ") + 5) + "px",
                    },
                })]),
            }), createElement(View_SwitchLanguage, null)]),
        })]),
    });
    return createElement("div", {
        className: join(" ", ["header-shadow"]),
        ref: navRef,
        children: Interop_reactApi.Children.toArray([createElement("nav", {
            style: {
                ["--dependsFromEmergencyBanner"]: isShowShowAnnouncementBanner ? "30px" : "0",
            },
            className: join(" ", ["navbar-fixed-nav", "headerHeightAuthorized"]),
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["navbar-fixed-black-desktop", "is-hidden-mobile"]),
                children: Interop_reactApi.Children.toArray([createElement("ul", {
                    style: {
                        float: "right",
                        marginRight: 20,
                    },
                    children: Interop_reactApi.Children.toArray([createElement("li", {
                        style: {
                            display: "inline-block",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("a", {
                            style: {
                                display: "flow-root",
                            },
                            href: toPath(new Route(14)),
                            className: join(" ", ["navbar-dark-item", "add_cursor_pointer"]),
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(createElement("i", {
                                className: join(" ", ["fa", "fa-gavel", "fa-flip-horizontal"]),
                            })), delay(() => {
                                const matchValue_2 = state_1.numberOfBids;
                                let pattern_matching_result_2, bids;
                                if (matchValue_2.tag === 1) {
                                    pattern_matching_result_2 = 0;
                                }
                                else if (matchValue_2.tag === 2) {
                                    if (matchValue_2.fields[0].tag === 1) {
                                        pattern_matching_result_2 = 2;
                                    }
                                    else {
                                        pattern_matching_result_2 = 1;
                                        bids = matchValue_2.fields[0].fields[0];
                                    }
                                }
                                else {
                                    pattern_matching_result_2 = 0;
                                }
                                switch (pattern_matching_result_2) {
                                    case 0: {
                                        return singleton_2(createElement("i", {
                                            style: {
                                                marginLeft: 5,
                                            },
                                            className: join(" ", ["fas", "fa-spinner", "fa-pulse"]),
                                        }));
                                    }
                                    case 1: {
                                        return singleton_2(createElement("span", {
                                            className: "navbar-dark-item-number",
                                            children: length(bids),
                                        }));
                                    }
                                    case 2: {
                                        return singleton_2(createElement("i", {
                                            style: {
                                                marginLeft: 5,
                                            },
                                            className: join(" ", ["fas", "fa-exclamation-circle"]),
                                        }));
                                    }
                                }
                            })))))),
                        })]),
                    }), createElement("li", {
                        style: {
                            display: "inline-block",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("a", {
                            style: {
                                display: "flow-root",
                            },
                            href: toPath(new Route(13)),
                            className: join(" ", ["navbar-dark-item", "add_cursor_pointer"]),
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(createElement("i", {
                                className: join(" ", ["fa", "fa-heart"]),
                            })), delay(() => {
                                const matchValue_3 = state_1.numberOfLiked;
                                let pattern_matching_result_3, liked;
                                if (matchValue_3.tag === 1) {
                                    pattern_matching_result_3 = 0;
                                }
                                else if (matchValue_3.tag === 2) {
                                    if (matchValue_3.fields[0].tag === 1) {
                                        pattern_matching_result_3 = 2;
                                    }
                                    else {
                                        pattern_matching_result_3 = 1;
                                        liked = matchValue_3.fields[0].fields[0];
                                    }
                                }
                                else {
                                    pattern_matching_result_3 = 0;
                                }
                                switch (pattern_matching_result_3) {
                                    case 0: {
                                        return singleton_2(createElement("i", {
                                            style: {
                                                marginLeft: 5,
                                            },
                                            className: join(" ", ["fas", "fa-spinner", "fa-pulse"]),
                                        }));
                                    }
                                    case 1: {
                                        return singleton_2(createElement("span", {
                                            className: "navbar-dark-item-number",
                                            children: liked,
                                        }));
                                    }
                                    case 2: {
                                        return singleton_2(createElement("i", {
                                            style: {
                                                marginLeft: 5,
                                            },
                                            className: join(" ", ["fas", "fa-exclamation-circle"]),
                                        }));
                                    }
                                }
                            })))))),
                        })]),
                    }), createElement("li", {
                        style: {
                            display: "inline-block",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("a", {
                            style: {
                                display: "flow-root",
                            },
                            href: toPath(new Route(17)),
                            className: join(" ", ["navbar-dark-item", "add_cursor_pointer"]),
                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(createElement("i", {
                                className: join(" ", ["fa", "fa-shopping-cart"]),
                            })), delay(() => {
                                const matchValue_4 = state_1.orders;
                                let pattern_matching_result_4, orders;
                                if (matchValue_4.tag === 1) {
                                    pattern_matching_result_4 = 0;
                                }
                                else if (matchValue_4.tag === 2) {
                                    if (matchValue_4.fields[0].tag === 1) {
                                        pattern_matching_result_4 = 2;
                                    }
                                    else {
                                        pattern_matching_result_4 = 1;
                                        orders = matchValue_4.fields[0].fields[0];
                                    }
                                }
                                else {
                                    pattern_matching_result_4 = 0;
                                }
                                switch (pattern_matching_result_4) {
                                    case 0: {
                                        return singleton_2(createElement("i", {
                                            style: {
                                                marginLeft: 5,
                                            },
                                            className: join(" ", ["fas", "fa-spinner", "fa-pulse"]),
                                        }));
                                    }
                                    case 1: {
                                        return singleton_2(createElement("span", {
                                            className: "navbar-dark-item-number",
                                            children: length(orders.NumberOfLotsInUnpaidOrders),
                                        }));
                                    }
                                    case 2: {
                                        return singleton_2(createElement("i", {
                                            style: {
                                                marginLeft: 5,
                                            },
                                            className: join(" ", ["fas", "fa-exclamation-circle"]),
                                        }));
                                    }
                                }
                            })))))),
                        })]),
                    }), createElement("li", {
                        style: {
                            display: "inline-block",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("a", {
                            style: {
                                display: "flow-root",
                            },
                            href: toPath(new Route(11)),
                            className: join(" ", ["navbar-dark-item", "add_cursor_pointer"]),
                            children: Interop_reactApi.Children.toArray([createElement("i", {
                                className: join(" ", ["fa", "fa-user"]),
                            }), createElement("span", {
                                className: "navbar-dark-item-number",
                                children: state_1.User.Initials,
                            })]),
                        })]),
                    })]),
                })]),
            }), createElement("div", {
                ref: navRef,
                className: join(" ", ["navbar", "is-family-secondary", "header-authorized", "headerPrimaryColor"]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(Interop_reactApi.createElement(scrollableAnchor, {
                    className: join(" ", ["backtotop", "fas", "fa-chevron-up", "is-medium"]),
                    href: "#top",
                    style: createObj(toList(delay(() => ((patternInput_2[1] !== 0) ? singleton_2(["display", "none"]) : empty())))),
                })), delay(() => append_1(singleton_2(createElement("div", {
                    className: join(" ", ["navbar-brand"]),
                    style: {
                        alignItems: "center",
                        justifyContent: "space-between",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("a", {
                        className: join(" ", ["navbar-item", "navbar-item-logo", "add_cursor_pointer"]),
                        href: toPath(new Route(0)),
                        onClick: (_arg4) => {
                            hide();
                        },
                        children: Interop_reactApi.Children.toArray([logo]),
                    }), createElement("div", {
                        style: {
                            display: "flex",
                        },
                        className: join(" ", ["is-hidden-tablet"]),
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            style: {
                                alignItems: "center",
                                justifyContent: "flex-end",
                                display: "flex",
                            },
                            children: Interop_reactApi.Children.toArray([createElement("ul", {
                                style: {
                                    lineHeight: 1 + "px",
                                },
                                children: Interop_reactApi.Children.toArray([createElement("li", {
                                    style: {
                                        display: "inline-block",
                                    },
                                    children: Interop_reactApi.Children.toArray([createElement("a", {
                                        href: toPath(new Route(14)),
                                        className: join(" ", ["navbar-light-item", "add_cursor_pointer"]),
                                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(createElement("i", {
                                            style: {
                                                fontSize: 19 + "px",
                                            },
                                            className: join(" ", ["fa", "fa-gavel", "fa-flip-horizontal"]),
                                        })), delay(() => {
                                            const matchValue_5 = state_1.numberOfBids;
                                            let pattern_matching_result_5, bids_1;
                                            if (matchValue_5.tag === 1) {
                                                pattern_matching_result_5 = 0;
                                            }
                                            else if (matchValue_5.tag === 2) {
                                                if (matchValue_5.fields[0].tag === 1) {
                                                    pattern_matching_result_5 = 2;
                                                }
                                                else {
                                                    pattern_matching_result_5 = 1;
                                                    bids_1 = matchValue_5.fields[0].fields[0];
                                                }
                                            }
                                            else {
                                                pattern_matching_result_5 = 0;
                                            }
                                            switch (pattern_matching_result_5) {
                                                case 0: {
                                                    return singleton_2(createElement("i", {
                                                        style: {
                                                            fontSize: 8 + "px",
                                                            padding: ((1 + "px ") + 4) + "px",
                                                            verticalAlign: "middle",
                                                        },
                                                        className: join(" ", ["fas", "fa-spinner", "fa-pulse"]),
                                                    }));
                                                }
                                                case 1: {
                                                    return singleton_2(createElement("span", {
                                                        style: {
                                                            fontSize: 8 + "px",
                                                            padding: ((1 + "px ") + 4) + "px",
                                                            verticalAlign: "middle",
                                                        },
                                                        children: length(bids_1),
                                                    }));
                                                }
                                                case 2: {
                                                    return singleton_2(createElement("i", {
                                                        style: {
                                                            fontSize: 8 + "px",
                                                            padding: ((1 + "px ") + 4) + "px",
                                                            verticalAlign: "middle",
                                                        },
                                                        className: join(" ", ["fas", "fa-exclamation-circle"]),
                                                    }));
                                                }
                                            }
                                        })))))),
                                    })]),
                                }), createElement("li", {
                                    style: {
                                        display: "inline-block",
                                    },
                                    children: Interop_reactApi.Children.toArray([createElement("a", {
                                        href: toPath(new Route(13)),
                                        className: join(" ", ["navbar-light-item", "add_cursor_pointer"]),
                                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(createElement("i", {
                                            style: {
                                                fontSize: 19 + "px",
                                            },
                                            className: join(" ", ["fa", "fa-heart"]),
                                        })), delay(() => {
                                            const matchValue_6 = state_1.numberOfLiked;
                                            let pattern_matching_result_6, liked_1;
                                            if (matchValue_6.tag === 1) {
                                                pattern_matching_result_6 = 0;
                                            }
                                            else if (matchValue_6.tag === 2) {
                                                if (matchValue_6.fields[0].tag === 1) {
                                                    pattern_matching_result_6 = 2;
                                                }
                                                else {
                                                    pattern_matching_result_6 = 1;
                                                    liked_1 = matchValue_6.fields[0].fields[0];
                                                }
                                            }
                                            else {
                                                pattern_matching_result_6 = 0;
                                            }
                                            switch (pattern_matching_result_6) {
                                                case 0: {
                                                    return singleton_2(createElement("i", {
                                                        style: {
                                                            fontSize: 8 + "px",
                                                            padding: ((1 + "px ") + 4) + "px",
                                                            verticalAlign: "middle",
                                                        },
                                                        className: join(" ", ["fas", "fa-spinner", "fa-pulse"]),
                                                    }));
                                                }
                                                case 1: {
                                                    return singleton_2(createElement("span", {
                                                        style: {
                                                            fontSize: 8 + "px",
                                                            padding: ((1 + "px ") + 4) + "px",
                                                            verticalAlign: "middle",
                                                        },
                                                        children: liked_1,
                                                    }));
                                                }
                                                case 2: {
                                                    return singleton_2(createElement("i", {
                                                        style: {
                                                            fontSize: 8 + "px",
                                                            padding: ((1 + "px ") + 4) + "px",
                                                            verticalAlign: "middle",
                                                        },
                                                        className: join(" ", ["fas", "fa-exclamation-circle"]),
                                                    }));
                                                }
                                            }
                                        })))))),
                                    })]),
                                }), createElement("li", {
                                    style: {
                                        display: "inline-block",
                                    },
                                    children: Interop_reactApi.Children.toArray([createElement("a", {
                                        href: toPath(new Route(17)),
                                        className: join(" ", ["navbar-light-item", "add_cursor_pointer"]),
                                        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append_1(singleton_2(createElement("i", {
                                            style: {
                                                fontSize: 19 + "px",
                                            },
                                            className: join(" ", ["fa", "fa-shopping-cart"]),
                                        })), delay(() => {
                                            const matchValue_7 = state_1.orders;
                                            let pattern_matching_result_7, orders_1;
                                            if (matchValue_7.tag === 1) {
                                                pattern_matching_result_7 = 0;
                                            }
                                            else if (matchValue_7.tag === 2) {
                                                if (matchValue_7.fields[0].tag === 1) {
                                                    pattern_matching_result_7 = 2;
                                                }
                                                else {
                                                    pattern_matching_result_7 = 1;
                                                    orders_1 = matchValue_7.fields[0].fields[0];
                                                }
                                            }
                                            else {
                                                pattern_matching_result_7 = 0;
                                            }
                                            switch (pattern_matching_result_7) {
                                                case 0: {
                                                    return singleton_2(createElement("i", {
                                                        style: {
                                                            fontSize: 8 + "px",
                                                            padding: ((1 + "px ") + 4) + "px",
                                                            verticalAlign: "middle",
                                                        },
                                                        className: join(" ", ["fas", "fa-spinner", "fa-pulse"]),
                                                    }));
                                                }
                                                case 1: {
                                                    return singleton_2(createElement("span", {
                                                        style: {
                                                            fontSize: 8 + "px",
                                                            padding: ((1 + "px ") + 4) + "px",
                                                            verticalAlign: "middle",
                                                        },
                                                        children: length(orders_1.NumberOfLotsInUnpaidOrders),
                                                    }));
                                                }
                                                case 2: {
                                                    return singleton_2(createElement("i", {
                                                        style: {
                                                            fontSize: 8 + "px",
                                                            padding: ((1 + "px ") + 4) + "px",
                                                            verticalAlign: "middle",
                                                        },
                                                        className: join(" ", ["fas", "fa-exclamation-circle"]),
                                                    }));
                                                }
                                            }
                                        })))))),
                                    })]),
                                })]),
                            })]),
                        }), createElement("div", {
                            className: join(" ", ["navbar-burger", "navbarBurgerCustom"]),
                            onClick: (_arg5) => {
                                setActive(!isActive);
                            },
                            ["aria-label"]: "menu",
                            ["aria-expanded"]: false,
                            children: Interop_reactApi.Children.toArray([createElement("span", {
                                ["aria-hidden"]: true,
                            }), createElement("span", {
                                ["aria-hidden"]: true,
                            }), createElement("span", {
                                ["aria-hidden"]: true,
                            })]),
                        })]),
                    })]),
                })), delay(() => ((width.tag === 0) ? singleton_2(createElement("div", {
                    className: join(" ", ["collapse"]),
                    style: createObj(toList(delay(() => (isActive ? singleton_2(["pointerEvents", "auto"]) : singleton_2(["pointerEvents", "none"]))))),
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: join(" ", toList(delay(() => append_1(singleton_2("collapse__panel"), delay(() => ((!isActive) ? singleton_2("collapse__panel--down") : singleton_2("collapse__panel--up"))))))),
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "burgerText",
                            children: Interop_reactApi.Children.toArray([items]),
                        })]),
                    })]),
                })) : ((width.tag === 1) ? singleton_2(createElement("div", {
                    className: join(" ", ["collapse"]),
                    style: createObj(toList(delay(() => (isActive ? singleton_2(["pointerEvents", "auto"]) : singleton_2(["pointerEvents", "none"]))))),
                    children: Interop_reactApi.Children.toArray([createElement("div", {
                        className: join(" ", toList(delay(() => append_1(singleton_2("collapse__panel"), delay(() => ((!isActive) ? singleton_2("collapse__panel--down") : singleton_2("collapse__panel--up"))))))),
                        children: Interop_reactApi.Children.toArray([createElement("div", {
                            className: "burgerText",
                            children: Interop_reactApi.Children.toArray([items]),
                        })]),
                    })]),
                })) : singleton_2(items))))))))))),
            })]),
        })]),
    });
}

