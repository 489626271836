import { getCurrentLanguage, t } from "../Localization.js";
import { Union, Record } from "../fable_modules/fable-library.3.6.1/Types.js";
import { bool_type, union_type, unit_type, record_type, list_type, string_type, class_type } from "../fable_modules/fable-library.3.6.1/Reflection.js";
import { Settings_addressToString, UploadImage_CountOfItems, UploadImage_MomsType, UploadImage_TypeOfItems, UploadImage_ItemsInfoSajabArgs, Settings_GetAccountError, UploadImage_InfoSajab, UploadImage_Item, Settings_GetAccountError$reflection, Settings_Account$reflection, UploadImage_CountOfItems$reflection, UploadImage_MomsType$reflection, UploadImage_TypeOfItems$reflection, UploadImage_InfoSajab$reflection, UploadImage_Image$reflection } from "../Shared/Shared.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.6.1/Choice.js";
import { Cmd_fromAsync, Deferred$1, AsyncOperationStatus$1, AsyncOperationStatus$1$reflection, Deferred$1$reflection } from "../Extensions.js";
import { length, cons, fold, ofArray, filter, append, singleton as singleton_1, empty as empty_2, map, isEmpty } from "../fable_modules/fable-library.3.6.1/List.js";
import { Validator$1__IsMail, Validator$1__IsValid_Z4CF01147, Validator$1__Test, Validator$1__NotBlank_2B595, Validator$1__End_Z5E18B1E2, validateSync } from "../fable_modules/Fable.Validation.0.2.1/Validation.fs.js";
import { tryFind, FSharpMap__get_IsEmpty, forAll, ofSeq, empty as empty_1 } from "../fable_modules/fable-library.3.6.1/Map.js";
import { BankInfo_testerBank } from "../Validation.js";
import { Form_getFieldError, Form_testerEmail, Form_testerPhone } from "../Common.js";
import { PhoneModule_ofString, PhoneModule_toString, PhoneModule_empty, EmailModule_empty, EmailModule_toString, EmailModule_ofString } from "../bidflow/src/infrastructure/Auction.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { imagesApi, settingsApi } from "../Communication.js";
import { newGuid } from "../fable_modules/fable-library.3.6.1/Guid.js";
import { Cmd_none, Cmd_batch } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { collect, empty as empty_3, append as append_1, singleton as singleton_2, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { GTM_Cmd_pushSubmissionFormSent } from "../Google.js";
import { useFeliz_React__React_useElmish_Static_645B1FB7 } from "../fable_modules/Feliz.UseElmish.1.5.1/UseElmish.fs.js";
import { useFeliz_React__React_useResponsive_Static_Z1648B8FF } from "../fable_modules/Feliz.UseMediaQuery.1.4.0/UseMediaQuery.fs.js";
import { createElement } from "react";
import { equals, createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { defaultArg } from "../fable_modules/fable-library.3.6.1/Option.js";
import { EditImages } from "../EditImages.js";
import { Route, toPath } from "../Router.js";

export function b(key) {
    return t("appointmentBooking." + key);
}

export function sap(key) {
    return t("sajab.appointmentBooking." + key);
}

export function p(key) {
    return t("common." + key);
}

export function ps(key) {
    return t("profileSettings." + key);
}

export class Item extends Record {
    constructor(Id, Title, Description, Images) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.Description = Description;
        this.Images = Images;
    }
}

export function Item$reflection() {
    return record_type("Client.Sajab.AppointmentBookingSajab.Item", [], Item, () => [["Id", class_type("System.Guid")], ["Title", string_type], ["Description", string_type], ["Images", list_type(UploadImage_Image$reflection())]]);
}

export class State extends Record {
    constructor(Info, Items, TypeOfItems, Moms, CountOfItems, SaveFormOperation, FormErrors, InfoErrors, NeedValidateForm, Lang) {
        super();
        this.Info = Info;
        this.Items = Items;
        this.TypeOfItems = TypeOfItems;
        this.Moms = Moms;
        this.CountOfItems = CountOfItems;
        this.SaveFormOperation = SaveFormOperation;
        this.FormErrors = FormErrors;
        this.InfoErrors = InfoErrors;
        this.NeedValidateForm = NeedValidateForm;
        this.Lang = Lang;
    }
}

export function State$reflection() {
    return record_type("Client.Sajab.AppointmentBookingSajab.State", [], State, () => [["Info", UploadImage_InfoSajab$reflection()], ["Items", list_type(Item$reflection())], ["TypeOfItems", UploadImage_TypeOfItems$reflection()], ["Moms", UploadImage_MomsType$reflection()], ["CountOfItems", UploadImage_CountOfItems$reflection()], ["SaveFormOperation", Deferred$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))], ["FormErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [class_type("System.Guid"), class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])])], ["InfoErrors", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, list_type(string_type)])], ["NeedValidateForm", bool_type], ["Lang", string_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TitleChanged", "DescriptionChanged", "ImagesChanged", "TypeOfItemsChanged", "MomsTypeChanged", "CountOfItemsChanged", "NameChanged", "PhoneChanged", "EmailChanged", "AddressChanged", "BankChanged", "BankNumberChanged", "ClearingNumberChanged", "ClientNumberChanged", "AddItem", "DeleteItem", "SaveForm", "LoadUser", "EventSentToGTM"];
    }
}

export function Msg$reflection() {
    return union_type("Client.Sajab.AppointmentBookingSajab.Msg", [], Msg, () => [[["Item1", class_type("System.Guid")], ["Item2", string_type]], [["Item1", class_type("System.Guid")], ["Item2", string_type]], [["Item1", class_type("System.Guid")], ["Item2", list_type(UploadImage_Image$reflection())]], [["Item", UploadImage_TypeOfItems$reflection()]], [["Item", UploadImage_MomsType$reflection()]], [["Item", UploadImage_CountOfItems$reflection()]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", class_type("System.Guid")]], [["Item", AsyncOperationStatus$1$reflection(union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]]))]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Settings_Account$reflection(), Settings_GetAccountError$reflection()], FSharpResult$2, () => [[["ResultValue", Settings_Account$reflection()]], [["ErrorValue", Settings_GetAccountError$reflection()]]])]], []]);
}

export function toItem(item) {
    return new UploadImage_Item(item.Title, item.Description, item.Images);
}

export function Validation_testImages(value) {
    return !isEmpty(value);
}

export function Validation_validate(item) {
    return validateSync(true, (v) => (new UploadImage_Item(Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.itemTitle"))(Validator$1__Test(v, "ItemTitle", item.Title))), Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.itemDescription"))(Validator$1__Test(v, "ItemDescription", item.Description))), Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (value) => Validation_testImages(value))(p("error.itemImages"))(Validator$1__Test(v, "ItemsImages", item.Images))))));
}

export function Validation_validateIf(condition, item) {
    if (condition) {
        const matchValue = Validation_validate(item);
        if (matchValue.tag === 1) {
            return [item.Id, matchValue.fields[0]];
        }
        else {
            return [item.Id, empty_1()];
        }
    }
    else {
        return [item.Id, empty_1()];
    }
}

export function Validation_validateInfo(info) {
    return validateSync(true, (v) => {
        let arg10_7;
        const Bank = Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.bank.name"))(Validator$1__Test(v, "Bank", info.Bank)));
        const ClearingNumber = Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.clearingRequired"))(Validator$1__IsValid_Z4CF01147(v, (value) => BankInfo_testerBank(value.split("")))(p("error.clearing"))(Validator$1__Test(v, "ClearingNumber", info.ClearingNumber))));
        const BankNumber = Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.bank.required"))(Validator$1__IsValid_Z4CF01147(v, (value_1) => BankInfo_testerBank(value_1.split("")))(p("error.bank.number"))(Validator$1__Test(v, "BankNumber", info.BankNumber))));
        const ClientNumber = Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.personal.number"))(Validator$1__IsValid_Z4CF01147(v, (value_2) => BankInfo_testerBank(value_2.split("")))(p("error.personal.containsNumber"))(Validator$1__Test(v, "ClientNumber", info.ClientNumber))));
        const Name = Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.name"))(Validator$1__Test(v, "Name", info.Name)));
        const Phone = Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (phone) => Form_testerPhone(phone))(p("error.phone"))(Validator$1__Test(v, "Phone", info.Phone)));
        return new UploadImage_InfoSajab(Name, EmailModule_ofString(Validator$1__End_Z5E18B1E2(v, Validator$1__IsValid_Z4CF01147(v, (email) => Form_testerEmail(email))(p("error.email"))((arg10_7 = Validator$1__Test(v, "Email", EmailModule_toString(info.Email)), Validator$1__IsMail(v, p("error.email"), arg10_7))))), Phone, Validator$1__End_Z5E18B1E2(v, Validator$1__NotBlank_2B595(v, p("error.address"))(Validator$1__Test(v, "Address", info.Address))), Bank, ClearingNumber, BankNumber, ClientNumber);
    });
}

export function Validation_validateInfoIf(condition, info) {
    if (condition) {
        const matchValue = Validation_validateInfo(info);
        if (matchValue.tag === 1) {
            return matchValue.fields[0];
        }
        else {
            return empty_1();
        }
    }
    else {
        return empty_1();
    }
}

export function getUser(userId) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => singleton.Bind(settingsApi().getAccount(userId), (_arg1) => singleton.Return(new Msg(17, _arg1)))), (_arg2) => singleton.Return(new Msg(17, new FSharpResult$2(1, new Settings_GetAccountError(0, userId))))));
}

export function saveItems(info, typeOfItems, moms, countOfItems, form) {
    return singleton.Delay(() => singleton.TryWith(singleton.Delay(() => {
        const sendItemsInfoSajabArgs = new UploadImage_ItemsInfoSajabArgs(info, typeOfItems, moms, countOfItems, map((item) => toItem(item), form));
        return singleton.Bind(imagesApi().sendItemsInfoSajab(sendItemsInfoSajabArgs), (_arg1) => singleton.Return(new Msg(16, new AsyncOperationStatus$1(1, _arg1))));
    }), (_arg2) => singleton.Return(new Msg(16, new AsyncOperationStatus$1(1, new FSharpResult$2(1, _arg2.message))))));
}

export function empty(id) {
    return new Item(id, "", "", empty_2());
}

export function init(user, unitVar1) {
    let Info, InfoErrors, FormErrors;
    const lng = getCurrentLanguage();
    let patternInput;
    if (user.tag === 0) {
        patternInput = ["", EmailModule_empty];
    }
    else {
        const x = user.fields[0];
        patternInput = [x.Name, x.Username];
    }
    const newId = newGuid();
    return [(Info = (new UploadImage_InfoSajab(patternInput[0], patternInput[1], PhoneModule_empty, "", "", "", "", "")), (InfoErrors = empty_1(), (FormErrors = ofSeq([[newId, empty_1()]]), new State(Info, singleton_1(empty(newId)), new UploadImage_TypeOfItems(false, false), new UploadImage_MomsType(false, false), new UploadImage_CountOfItems(0), new Deferred$1(0), FormErrors, InfoErrors, false, lng)))), Cmd_batch(toList(delay(() => ((user.tag === 0) ? singleton_2(Cmd_none()) : singleton_2(Cmd_fromAsync(getUser(user.fields[0].UserId)))))))];
}

export function withForm(form, state) {
    return new State(state.Info, form, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang);
}

export function validate(state) {
    return new State(state.Info, state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, ofSeq(map((x) => Validation_validateIf(state.NeedValidateForm, x), state.Items)), state.InfoErrors, state.NeedValidateForm, state.Lang);
}

export function validateInfo(state) {
    return new State(state.Info, state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, Validation_validateInfoIf(state.NeedValidateForm, state.Info), state.NeedValidateForm, state.Lang);
}

export function update(msg, state) {
    let inputRecord, inputRecord_1, inputRecord_2, inputRecord_3, inputRecord_4, inputRecord_5, inputRecord_6, inputRecord_7, inputRecord_8;
    if (msg.tag === 1) {
        return [validate(new State(state.Info, map((item_1) => {
            if (item_1.Id === msg.fields[0]) {
                return new Item(item_1.Id, item_1.Title, msg.fields[1], item_1.Images);
            }
            else {
                return item_1;
            }
        }, state.Items), state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [validate(new State(state.Info, map((item_2) => {
            if (item_2.Id === msg.fields[0]) {
                return new Item(item_2.Id, item_2.Title, item_2.Description, msg.fields[1]);
            }
            else {
                return item_2;
            }
        }, state.Items), state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new State(state.Info, state.Items, msg.fields[0], state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang), Cmd_none()];
    }
    else if (msg.tag === 4) {
        return [new State(state.Info, state.Items, state.TypeOfItems, msg.fields[0], state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new State(state.Info, state.Items, state.TypeOfItems, state.Moms, msg.fields[0], state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang), Cmd_none()];
    }
    else if (msg.tag === 7) {
        return [validateInfo(new State((inputRecord = state.Info, new UploadImage_InfoSajab(inputRecord.Name, inputRecord.Email, msg.fields[0], inputRecord.Address, inputRecord.Bank, inputRecord.ClearingNumber, inputRecord.BankNumber, inputRecord.ClientNumber)), state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 6) {
        return [validateInfo(new State((inputRecord_1 = state.Info, new UploadImage_InfoSajab(msg.fields[0], inputRecord_1.Email, inputRecord_1.Phone, inputRecord_1.Address, inputRecord_1.Bank, inputRecord_1.ClearingNumber, inputRecord_1.BankNumber, inputRecord_1.ClientNumber)), state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 8) {
        return [validateInfo(new State((inputRecord_2 = state.Info, new UploadImage_InfoSajab(inputRecord_2.Name, msg.fields[0], inputRecord_2.Phone, inputRecord_2.Address, inputRecord_2.Bank, inputRecord_2.ClearingNumber, inputRecord_2.BankNumber, inputRecord_2.ClientNumber)), state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 9) {
        return [validateInfo(new State((inputRecord_3 = state.Info, new UploadImage_InfoSajab(inputRecord_3.Name, inputRecord_3.Email, inputRecord_3.Phone, msg.fields[0], inputRecord_3.Bank, inputRecord_3.ClearingNumber, inputRecord_3.BankNumber, inputRecord_3.ClientNumber)), state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 10) {
        return [validateInfo(new State((inputRecord_4 = state.Info, new UploadImage_InfoSajab(inputRecord_4.Name, inputRecord_4.Email, inputRecord_4.Phone, inputRecord_4.Address, msg.fields[0], inputRecord_4.ClearingNumber, inputRecord_4.BankNumber, inputRecord_4.ClientNumber)), state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 11) {
        return [validateInfo(new State((inputRecord_5 = state.Info, new UploadImage_InfoSajab(inputRecord_5.Name, inputRecord_5.Email, inputRecord_5.Phone, inputRecord_5.Address, inputRecord_5.Bank, inputRecord_5.ClearingNumber, msg.fields[0], inputRecord_5.ClientNumber)), state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 12) {
        return [validateInfo(new State((inputRecord_6 = state.Info, new UploadImage_InfoSajab(inputRecord_6.Name, inputRecord_6.Email, inputRecord_6.Phone, inputRecord_6.Address, inputRecord_6.Bank, msg.fields[0], inputRecord_6.BankNumber, inputRecord_6.ClientNumber)), state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 13) {
        return [validateInfo(new State((inputRecord_7 = state.Info, new UploadImage_InfoSajab(inputRecord_7.Name, inputRecord_7.Email, inputRecord_7.Phone, inputRecord_7.Address, inputRecord_7.Bank, inputRecord_7.ClearingNumber, inputRecord_7.BankNumber, msg.fields[0])), state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
    else if (msg.tag === 14) {
        return [new State(state.Info, append(state.Items, singleton_1(empty(newGuid()))), state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang), Cmd_none()];
    }
    else if (msg.tag === 15) {
        return [new State(state.Info, filter((item_3) => (item_3.Id !== msg.fields[0]), state.Items), state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang), Cmd_none()];
    }
    else if (msg.tag === 17) {
        if (msg.fields[0].tag === 1) {
            return [state, Cmd_none()];
        }
        else {
            const account = msg.fields[0].fields[0];
            let patternInput;
            if (account.tag === 1) {
                const x_11 = account.fields[0];
                patternInput = [x_11.ContactPerson.Phone, Settings_addressToString(state.Lang, x_11.Address), x_11.BankInfo.Bank, x_11.BankInfo.BankNumber, x_11.BankInfo.ClearingNumber, x_11.CompanyInfo.OrganizationNumber];
            }
            else {
                const x_10 = account.fields[0];
                patternInput = [x_10.PersonalInfo.Phone, Settings_addressToString(state.Lang, x_10.Address), x_10.BankInfo.Bank, x_10.BankInfo.BankNumber, x_10.BankInfo.ClearingNumber, x_10.PersonalInfo.PersonalNumber];
            }
            return [new State((inputRecord_8 = state.Info, new UploadImage_InfoSajab(inputRecord_8.Name, inputRecord_8.Email, patternInput[0], patternInput[1], patternInput[2], patternInput[4], patternInput[3], patternInput[5])), state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang), Cmd_none()];
        }
    }
    else if (msg.tag === 16) {
        if (msg.fields[0].tag === 1) {
            return [new State(state.Info, state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, new Deferred$1(2, msg.fields[0].fields[0]), state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang), GTM_Cmd_pushSubmissionFormSent(new Msg(18))];
        }
        else {
            const errorsInfo = Validation_validateInfoIf(true, state.Info);
            const errors = ofSeq(map((x_13) => Validation_validateIf(true, x_13), state.Items));
            if (forAll((_arg1, e) => FSharpMap__get_IsEmpty(e), errors) ? FSharpMap__get_IsEmpty(errorsInfo) : false) {
                return [new State(state.Info, state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, new Deferred$1(1), errors, errorsInfo, true, state.Lang), Cmd_fromAsync(saveItems(state.Info, state.TypeOfItems, state.Moms, state.CountOfItems, state.Items))];
            }
            else {
                return [new State(state.Info, state.Items, state.TypeOfItems, state.Moms, state.CountOfItems, new Deferred$1(0), errors, errorsInfo, true, state.Lang), Cmd_none()];
            }
        }
    }
    else if (msg.tag === 18) {
        return [state, Cmd_none()];
    }
    else {
        return [validate(new State(state.Info, map((item) => {
            if (item.Id === msg.fields[0]) {
                return new Item(item.Id, msg.fields[1], item.Description, item.Images);
            }
            else {
                return item;
            }
        }, state.Items), state.TypeOfItems, state.Moms, state.CountOfItems, state.SaveFormOperation, state.FormErrors, state.InfoErrors, state.NeedValidateForm, state.Lang)), Cmd_none()];
    }
}

export const dividedTop = ["style", {
    borderTopStyle: "solid",
    borderTopWidth: 1 + "px",
    borderTopColor: "#c4c4c4",
}];

export function View(viewInputProps) {
    const isSeparatePage = viewInputProps.isSeparatePage;
    const user = viewInputProps.user;
    const patternInput = useFeliz_React__React_useElmish_Static_645B1FB7(() => init(user, void 0), (msg, state) => update(msg, state), []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const width = useFeliz_React__React_useResponsive_Static_Z1648B8FF();
    const isSaving = (state_1.SaveFormOperation.tag === 1) ? true : false;
    let saveError;
    const matchValue_1 = state_1.SaveFormOperation;
    let pattern_matching_result, error;
    if (matchValue_1.tag === 2) {
        if (matchValue_1.fields[0].tag === 1) {
            pattern_matching_result = 0;
            error = matchValue_1.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            const props = ofArray([["className", "is-danger"], ["style", {
                marginTop: 10,
            }], ["children", error]]);
            saveError = createElement("div", createObj(Helpers_combineClasses("notification", props)));
            break;
        }
        case 1: {
            saveError = null;
            break;
        }
    }
    let isSavedSuccessfully;
    const matchValue_2 = state_1.SaveFormOperation;
    let pattern_matching_result_1;
    if (matchValue_2.tag === 2) {
        if (matchValue_2.fields[0].tag === 0) {
            pattern_matching_result_1 = 0;
        }
        else {
            pattern_matching_result_1 = 1;
        }
    }
    else {
        pattern_matching_result_1 = 1;
    }
    switch (pattern_matching_result_1) {
        case 0: {
            isSavedSuccessfully = true;
            break;
        }
        case 1: {
            isSavedSuccessfully = false;
            break;
        }
    }
    const field = (label, errors_4, value_97, onChange) => {
        let elms_5, props_39, props_33, elms_4, props_37;
        const props_42 = ofArray([["className", "is-6"], ["children", Interop_reactApi.Children.toArray([(elms_5 = singleton_1((props_39 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_33 = ofArray([["className", "has-text-left"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", label]]))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_33)))), (elms_4 = singleton_1(createElement("input", createObj(toList(delay(() => append_1(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append_1((!isEmpty(errors_4)) ? singleton_2(["style", {
            borderColor: "#FF0000",
            boxShadow: "none",
        }]) : empty_3(), delay(() => append_1(singleton_2(["type", "text"]), delay(() => {
            let value_119;
            return append_1(singleton_2((value_119 = value_97, ["ref", (e_2) => {
                if ((!(e_2 == null)) ? (!equals(e_2.value, value_119)) : false) {
                    e_2.value = value_119;
                }
            }])), delay(() => singleton_2(["onChange", (ev_2) => {
                onChange(ev_2.target.value);
            }])));
        }))))))))))), createElement("div", {
            className: "field-body",
            children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
        })), (props_37 = ofArray([["style", {
            color: "#FF0000",
        }], ["children", fold((s_3, x_6) => (`${s_3} ${x_6}`), "", errors_4)]]), createElement("p", createObj(Helpers_combineClasses("help", props_37))))])]]), createElement("div", createObj(Helpers_combineClasses("control", props_39))))), createElement("div", {
            className: "field",
            children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
        }))])]]);
        return createElement("div", createObj(Helpers_combineClasses("column", props_42)));
    };
    const bookingAppointment = createElement("div", {
        disabled: isSaving,
        children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
            let props_44;
            return append_1(singleton_2((props_44 = ofArray([["className", "is-multiline"], ["className", "is-mobile"], ["children", Interop_reactApi.Children.toArray([field(ps("name"), Form_getFieldError(state_1.InfoErrors, "Name"), state_1.Info.Name, (arg) => {
                dispatch(new Msg(6, arg));
            }), field(ps("email.placeholder"), Form_getFieldError(state_1.InfoErrors, "Email"), EmailModule_toString(state_1.Info.Email), (arg_2) => {
                dispatch(new Msg(8, EmailModule_ofString(arg_2)));
            }), field(ps("phone.placeholder"), Form_getFieldError(state_1.InfoErrors, "Phone"), PhoneModule_toString(state_1.Info.Phone), (arg_4) => {
                dispatch(new Msg(7, PhoneModule_ofString(arg_4)));
            }), field(ps("address.address"), Form_getFieldError(state_1.InfoErrors, "Address"), state_1.Info.Address, (arg_5) => {
                dispatch(new Msg(9, arg_5));
            }), field(ps("bank.placeholder"), Form_getFieldError(state_1.InfoErrors, "Bank"), state_1.Info.Bank, (arg_6) => {
                dispatch(new Msg(10, arg_6));
            }), field(ps("banknumber.placeholder"), Form_getFieldError(state_1.InfoErrors, "BankNumber"), state_1.Info.BankNumber, (arg_7) => {
                dispatch(new Msg(11, arg_7));
            }), field(ps("personal.number.placeholder"), Form_getFieldError(state_1.InfoErrors, "ClientNumber"), state_1.Info.ClientNumber, (arg_8) => {
                dispatch(new Msg(13, arg_8));
            }), field(ps("clearing.number.placeholder"), Form_getFieldError(state_1.InfoErrors, "ClearingNumber"), state_1.Info.ClearingNumber, (arg_9) => {
                dispatch(new Msg(12, arg_9));
            })])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_44))))), delay(() => {
                let elms_8, props_64, props_48, props_46, props_62, props_54, elms_6, props_60, elms_7;
                return append_1(singleton_2((elms_8 = singleton_1((props_64 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_48 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_46 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", sap("question1.title")]]), createElement("label", createObj(Helpers_combineClasses("label", props_46))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_48)))), (props_62 = ofArray([["style", {
                    display: "grid",
                    justifyContent: "flex-start",
                }], ["children", Interop_reactApi.Children.toArray([(props_54 = ofArray([["style", {
                    marginBottom: 10,
                    display: "flex",
                }], ["children", Interop_reactApi.Children.toArray([(elms_6 = ofArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", ofArray([["id", "allStaff"], ["className", "is-success"], ["checked", state_1.TypeOfItems.All], ["onChange", (ev_3) => {
                    dispatch(new Msg(3, new UploadImage_TypeOfItems(ev_3.target.checked, state_1.TypeOfItems.Tractors)));
                }]]))))), createElement("label", {
                    className: "fontSize-desktop20-tablet18-mobile12",
                    htmlFor: "allStaff",
                    children: sap("question1.variant1"),
                })]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_54)))), (props_60 = ofArray([["style", {
                    marginBottom: 30,
                    display: "flex",
                }], ["children", Interop_reactApi.Children.toArray([(elms_7 = ofArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", ofArray([["id", "tractors"], ["className", "is-success"], ["checked", state_1.TypeOfItems.Tractors], ["onChange", (ev_4) => {
                    dispatch(new Msg(3, new UploadImage_TypeOfItems(state_1.TypeOfItems.All, ev_4.target.checked)));
                }]]))))), createElement("label", {
                    className: "fontSize-desktop20-tablet18-mobile12",
                    htmlFor: "tractors",
                    children: sap("question1.variant2"),
                })]), createElement("div", {
                    className: "control",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
                }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_60))))])]]), createElement("div", createObj(Helpers_combineClasses("field-body", props_62))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_64))))), createElement("div", {
                    className: "field",
                    children: Interop_reactApi.Children.toArray(Array.from(elms_8)),
                }))), delay(() => {
                    let elms_11, props_85, props_69, props_67, props_83, props_75, elms_9, props_81, elms_10;
                    return append_1(singleton_2((elms_11 = singleton_1((props_85 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_69 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_67 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", sap("question2.title")]]), createElement("label", createObj(Helpers_combineClasses("label", props_67))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_69)))), (props_83 = ofArray([["style", {
                        display: "grid",
                        justifyContent: "flex-start",
                    }], ["children", Interop_reactApi.Children.toArray([(props_75 = ofArray([["style", {
                        marginBottom: 10,
                        display: "flex",
                    }], ["children", Interop_reactApi.Children.toArray([(elms_9 = ofArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", ofArray([["id", "withMoms"], ["className", "is-success"], ["checked", state_1.Moms.WithMoms], ["onChange", (ev_5) => {
                        dispatch(new Msg(4, new UploadImage_MomsType(ev_5.target.checked, state_1.Moms.WithoutMoms)));
                    }]]))))), createElement("label", {
                        className: "fontSize-desktop20-tablet18-mobile12",
                        htmlFor: "withMoms",
                        children: sap("question2.variant1"),
                    })]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_9)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_75)))), (props_81 = ofArray([["style", {
                        marginBottom: 30,
                        display: "flex",
                    }], ["children", Interop_reactApi.Children.toArray([(elms_10 = ofArray([createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", ofArray([["id", "withoutMoms"], ["className", "is-success"], ["checked", state_1.Moms.WithoutMoms], ["onChange", (ev_6) => {
                        dispatch(new Msg(4, new UploadImage_MomsType(state_1.Moms.WithMoms, ev_6.target.checked)));
                    }]]))))), createElement("label", {
                        className: "fontSize-desktop20-tablet18-mobile12",
                        htmlFor: "withoutMoms",
                        children: sap("question2.variant2"),
                    })]), createElement("div", {
                        className: "control",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_10)),
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_81))))])]]), createElement("div", createObj(Helpers_combineClasses("field-body", props_83))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_85))))), createElement("div", {
                        className: "field",
                        children: Interop_reactApi.Children.toArray(Array.from(elms_11)),
                    }))), delay(() => {
                        let elms_16, props_118, props_90, props_88, props_116, props_96, elms_12, props_92, props_102, elms_13, props_98, props_108, elms_14, props_104, props_114, elms_15, props_110;
                        return append_1(singleton_2((elms_16 = singleton_1((props_118 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_90 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_88 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", sap("question3.title")]]), createElement("label", createObj(Helpers_combineClasses("label", props_88))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_90)))), (props_116 = ofArray([["style", {
                            display: "grid",
                            justifyContent: "flex-start",
                        }], ["children", Interop_reactApi.Children.toArray([(props_96 = ofArray([["style", {
                            marginBottom: 10,
                            display: "flex",
                        }], ["children", Interop_reactApi.Children.toArray([(elms_12 = ofArray([(props_92 = ofArray([["id", "LessThan10"], ["className", "is-success"], ["checked", equals(state_1.CountOfItems, new UploadImage_CountOfItems(0))], ["onChange", (ev_7) => {
                            const x_11 = ev_7.target.checked;
                            dispatch(new Msg(5, new UploadImage_CountOfItems(0)));
                        }]]), createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", props_92))))), createElement("label", {
                            className: "fontSize-desktop20-tablet18-mobile12",
                            htmlFor: "LessThan10",
                            children: sap("question3.variant1"),
                        })]), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_12)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_96)))), (props_102 = ofArray([["style", {
                            marginBottom: 10,
                            display: "flex",
                        }], ["children", Interop_reactApi.Children.toArray([(elms_13 = ofArray([(props_98 = ofArray([["id", "From11To25"], ["className", "is-success"], ["checked", equals(state_1.CountOfItems, new UploadImage_CountOfItems(1))], ["onChange", (ev_8) => {
                            const x_12 = ev_8.target.checked;
                            dispatch(new Msg(5, new UploadImage_CountOfItems(1)));
                        }]]), createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", props_98))))), createElement("label", {
                            className: "fontSize-desktop20-tablet18-mobile12",
                            htmlFor: "From11To25",
                            children: sap("question3.variant2"),
                        })]), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_13)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_102)))), (props_108 = ofArray([["style", {
                            marginBottom: 10,
                            display: "flex",
                        }], ["children", Interop_reactApi.Children.toArray([(elms_14 = ofArray([(props_104 = ofArray([["id", "From26To50"], ["className", "is-success"], ["checked", equals(state_1.CountOfItems, new UploadImage_CountOfItems(2))], ["onChange", (ev_9) => {
                            const x_13 = ev_9.target.checked;
                            dispatch(new Msg(5, new UploadImage_CountOfItems(2)));
                        }]]), createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", props_104))))), createElement("label", {
                            className: "fontSize-desktop20-tablet18-mobile12",
                            htmlFor: "From26To50",
                            children: sap("question3.variant3"),
                        })]), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_14)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_108)))), (props_114 = ofArray([["style", {
                            marginBottom: 30,
                            display: "flex",
                        }], ["children", Interop_reactApi.Children.toArray([(elms_15 = ofArray([(props_110 = ofArray([["id", "MoreThan50"], ["className", "is-success"], ["checked", equals(state_1.CountOfItems, new UploadImage_CountOfItems(3))], ["onChange", (ev_10) => {
                            const x_14 = ev_10.target.checked;
                            dispatch(new Msg(5, new UploadImage_CountOfItems(3)));
                        }]]), createElement("input", createObj(cons(["type", "checkbox"], Helpers_combineClasses("is-checkradio", props_110))))), createElement("label", {
                            className: "fontSize-desktop20-tablet18-mobile12",
                            htmlFor: "MoreThan50",
                            children: sap("question3.variant4"),
                        })]), createElement("div", {
                            className: "control",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_15)),
                        }))])]]), createElement("div", createObj(Helpers_combineClasses("field", props_114))))])]]), createElement("div", createObj(Helpers_combineClasses("field-body", props_116))))])]), createElement("div", createObj(Helpers_combineClasses("control", props_118))))), createElement("div", {
                            className: "field",
                            children: Interop_reactApi.Children.toArray(Array.from(elms_16)),
                        }))), delay(() => append_1(collect((item_1) => {
                            let dispatch_1, errors, itemsCount_1, item, elms_1, props_11, elms_3, props_23, props_28, props_26;
                            return singleton_2((dispatch_1 = dispatch, (errors = defaultArg(tryFind(item_1.Id, state_1.FormErrors), empty_1()), (itemsCount_1 = (length(state_1.Items) | 0), (item = item_1, createElement("div", {
                                className: join(" ", toList(delay(() => (isSeparatePage ? singleton_2("appointment-booking-box") : singleton_2("appointment-booking-box-saparate"))))),
                                style: {
                                    borderRadius: 10,
                                },
                                key: `${item.Id}`,
                                children: Interop_reactApi.Children.toArray([(itemsCount_1 === 1) ? null : createElement("button", {
                                    style: {
                                        float: "right",
                                    },
                                    className: "delete",
                                    onClick: (_arg1) => {
                                        dispatch(new Msg(15, item.Id));
                                    },
                                }), (elms_1 = singleton_1((props_11 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                    let props_5, props_3;
                                    const errors_1 = Form_getFieldError(errors, "ItemTitle");
                                    return append_1(singleton_2((props_5 = ofArray([["className", "has-text-left"], ["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_3 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", b("item.title")]]), createElement("label", createObj(Helpers_combineClasses("label", props_3))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_5))))), delay(() => {
                                        let elms;
                                        return append_1(singleton_2((elms = singleton_1(createElement("input", createObj(toList(delay(() => append_1(singleton_2(["className", join(" ", ["input", "is-medium"])]), delay(() => append_1((!isEmpty(errors_1)) ? singleton_2(["style", {
                                            borderColor: "#FF0000",
                                            boxShadow: "none",
                                        }]) : empty_3(), delay(() => append_1(singleton_2(["type", "text"]), delay(() => {
                                            let value_36;
                                            return append_1(singleton_2((value_36 = item.Title, ["ref", (e) => {
                                                if ((!(e == null)) ? (!equals(e.value, value_36)) : false) {
                                                    e.value = value_36;
                                                }
                                            }])), delay(() => singleton_2(["onChange", (ev) => {
                                                dispatch_1(new Msg(0, item.Id, ev.target.value));
                                            }])));
                                        }))))))))))), createElement("div", {
                                            className: "field-body",
                                            children: Interop_reactApi.Children.toArray(Array.from(elms)),
                                        }))), delay(() => {
                                            let props_9;
                                            return singleton_2((props_9 = ofArray([["style", {
                                                color: "#FF0000",
                                            }], ["children", fold((s, x_1) => (`${s} ${x_1}`), "", errors_1)]]), createElement("p", createObj(Helpers_combineClasses("help", props_9)))));
                                        }));
                                    }));
                                }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_11))))), createElement("div", {
                                    className: "field",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
                                })), (elms_3 = singleton_1((props_23 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => {
                                    let props_16, props_14;
                                    const errors_2 = Form_getFieldError(errors, "ItemDescription");
                                    return append_1(singleton_2((props_16 = ofArray([["className", "has-text-left"], ["children", Interop_reactApi.Children.toArray([(props_14 = ofArray([["className", "mb-1"], ["className", "has-text-grey"], ["children", b("item.description")]]), createElement("label", createObj(Helpers_combineClasses("label", props_14))))])]]), createElement("div", createObj(Helpers_combineClasses("field-label", props_16))))), delay(() => {
                                        let elms_2, props_18;
                                        return append_1(singleton_2((elms_2 = singleton_1((props_18 = toList(delay(() => append_1((!isEmpty(errors_2)) ? singleton_2(["style", {
                                            borderColor: "#FF0000",
                                            boxShadow: "none",
                                        }]) : empty_3(), delay(() => append_1(singleton_2(["className", join(" ", ["is-medium"])]), delay(() => append_1(singleton_2(["disabled", isSaving]), delay(() => {
                                            let value_70;
                                            return append_1(singleton_2((value_70 = item.Description, ["ref", (e_1) => {
                                                if ((!(e_1 == null)) ? (!equals(e_1.value, value_70)) : false) {
                                                    e_1.value = value_70;
                                                }
                                            }])), delay(() => singleton_2(["onChange", (ev_1) => {
                                                dispatch_1(new Msg(1, item.Id, ev_1.target.value));
                                            }])));
                                        })))))))), createElement("textarea", createObj(Helpers_combineClasses("textarea", props_18))))), createElement("div", {
                                            className: "field-body",
                                            children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
                                        }))), delay(() => {
                                            let props_21;
                                            return singleton_2((props_21 = ofArray([["style", {
                                                color: "#FF0000",
                                            }], ["children", fold((s_1, x_3) => (`${s_1} ${x_3}`), "", errors_2)]]), createElement("p", createObj(Helpers_combineClasses("help", props_21)))));
                                        }));
                                    }));
                                }))))]]), createElement("div", createObj(Helpers_combineClasses("control", props_23))))), createElement("div", {
                                    className: "field",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
                                })), createElement(EditImages, {
                                    images: item.Images,
                                    onImagesChanged: (x_4) => {
                                        dispatch_1(new Msg(2, item.Id, x_4));
                                    },
                                    dependencies: [isSavedSuccessfully],
                                }), (props_28 = ofArray([["style", {
                                    paddingTop: 0,
                                }], ["children", Interop_reactApi.Children.toArray([(props_26 = toList(delay(() => {
                                    const errors_3 = Form_getFieldError(errors, "ItemsImages");
                                    return append_1(singleton_2(["className", "has-text-left"]), delay(() => append_1(singleton_2(["style", {
                                        color: "#FF0000",
                                    }]), delay(() => singleton_2(["children", fold((s_2, x_5) => (`${s_2} ${x_5}`), "", errors_3)])))));
                                })), createElement("p", createObj(Helpers_combineClasses("help", props_26))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_28))))]),
                            }))))));
                        }, state_1.Items), delay(() => {
                            let props_129, props_125, props_122, s_6, props_127;
                            return append_1(singleton_2((props_129 = ofArray([["className", "is-right"], ["children", Interop_reactApi.Children.toArray([(props_125 = ofArray([["className", "is-light"], ["onClick", (x_15) => {
                                x_15.preventDefault();
                                dispatch(new Msg(14));
                            }], ["children", Interop_reactApi.Children.toArray([(props_122 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("i", {
                                className: join(" ", ["fa", "fa-plus"]),
                            })])]), createElement("span", createObj(Helpers_combineClasses("icon", props_122)))), (s_6 = b("add.item.button"), createElement("span", {
                                className: "",
                                children: s_6,
                            }))])]]), createElement("button", createObj(Helpers_combineClasses("button", props_125)))), (props_127 = toList(delay(() => append_1(singleton_2(["disabled", ((!state_1.Moms.WithMoms) ? (!state_1.Moms.WithoutMoms) : false) ? true : ((!state_1.TypeOfItems.All) ? (!state_1.TypeOfItems.Tractors) : false)]), delay(() => append_1(isSaving ? singleton_2(["className", "is-loading"]) : empty_3(), delay(() => append_1(singleton_2(["className", "is-primary"]), delay(() => append_1(singleton_2(["onClick", (e_3) => {
                                e_3.preventDefault();
                                dispatch(new Msg(16, new AsyncOperationStatus$1(0)));
                            }]), delay(() => singleton_2(["children", b("submit.button")]))))))))))), createElement("button", createObj(Helpers_combineClasses("button", props_127))))])]]), createElement("div", createObj(Helpers_combineClasses("buttons", props_129))))), delay(() => {
                                let elms_17;
                                return singleton_2((elms_17 = singleton_1(createElement("div", {
                                    className: "control",
                                    children: Interop_reactApi.Children.toArray([saveError]),
                                })), createElement("div", {
                                    className: "field",
                                    children: Interop_reactApi.Children.toArray(Array.from(elms_17)),
                                })));
                            }));
                        }))));
                    }));
                }));
            }));
        })))),
    });
    const matchValue_3 = state_1.SaveFormOperation;
    let pattern_matching_result_2;
    if (matchValue_3.tag === 2) {
        if (matchValue_3.fields[0].tag === 0) {
            pattern_matching_result_2 = 0;
        }
        else {
            pattern_matching_result_2 = 1;
        }
    }
    else {
        pattern_matching_result_2 = 1;
    }
    switch (pattern_matching_result_2) {
        case 0: {
            if (isSeparatePage) {
                return createElement("div", createObj(toList(delay(() => append_1(singleton_2(["className", join(" ", ["background-container-login-forgot-create", "create-account-column"])]), delay(() => append_1((width.tag === 0) ? singleton_2(["style", {}]) : ((width.tag === 1) ? singleton_2(["style", {}]) : ((width.tag === 2) ? singleton_2(["style", {
                    minHeight: 814,
                }]) : singleton_2(["style", {
                    minHeight: 500,
                }]))), delay(() => {
                    let props_139, value_434;
                    return singleton_2(["children", Interop_reactApi.Children.toArray([(props_139 = ofArray([["className", "login-box"], ["style", {
                        maxHeight: 400,
                    }], ["children", Interop_reactApi.Children.toArray([createElement("div", {
                        style: {
                            padding: 20,
                            marginBottom: 20,
                            borderBottom: (((1 + "px ") + "dashed") + " ") + "#ccc",
                        },
                        children: Interop_reactApi.Children.toArray([createElement("p", {
                            className: join(" ", ["create-account-title"]),
                            children: b("success.title"),
                        }), createElement("div", {
                            className: "buy-text",
                            children: Interop_reactApi.Children.toArray([(value_434 = b("success.notification"), createElement("p", {
                                children: [value_434],
                            }))]),
                        })]),
                    }), createElement("a", {
                        className: join(" ", ["button", "buy-button-big"]),
                        children: b("Ok"),
                        href: toPath(new Route(0)),
                    })])]]), createElement("div", createObj(Helpers_combineClasses("box", props_139))))])]);
                }))))))));
            }
            else {
                return createElement("div", {
                    className: "has-background-success",
                    style: {
                        padding: 20,
                        marginBottom: 20,
                        color: "#FFFFFF",
                        textAlign: "center",
                    },
                    children: Interop_reactApi.Children.toArray([createElement("p", {
                        style: {
                            fontSize: 24 + "px",
                        },
                        children: b("success.title"),
                    }), createElement("div", {
                        style: {
                            color: "#FFFFFF",
                        },
                        children: b("success.notification"),
                    })]),
                });
            }
        }
        case 1: {
            return bookingAppointment;
        }
    }
}

