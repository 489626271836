import { tWithArgs, t } from "../Localization.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { homeApi } from "../Communication.js";
import { Settings_CompanySettingsInfo_get_empty } from "../Shared/Shared.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Img_homeBackgroundSajab } from "../Css.js";

export function p(key) {
    return t("home." + key);
}

export function ViewHomeBlock() {
    let props_23, props_21, props_4, props_2, elms, props_6, props_8, elms_1, props_13, elms_2, props_18, props_29, props_25, props_27, props_50, props_31, elms_7, elms_4, elms_3, s, s_1, s_2, elms_6, elms_5, s_4, s_5, s_6, props_56;
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getCompanyInfo(), []);
    const set$_1 = (settings.tag === 2) ? settings.fields[0] : Settings_CompanySettingsInfo_get_empty();
    const children_8 = ofArray([(props_23 = ofArray([["className", "homeBox"], ["children", Interop_reactApi.Children.toArray([(props_21 = ofArray([["className", "is-vcentered"], ["className", "is-centered"], ["children", Interop_reactApi.Children.toArray([(props_4 = singleton(["children", Interop_reactApi.Children.toArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "sajabHomeBoxTitle"], ["children", "2011"]])))), (props_2 = ofArray([["style", {
        fontSize: 14 + "px",
        textAlign: "center",
    }], ["children", t("sajab.home.text1")]]), createElement("p", createObj(Helpers_combineClasses("", props_2))))])]), createElement("div", createObj(Helpers_combineClasses("column", props_4)))), (elms = ofArray([(props_6 = ofArray([["className", "sajabHomeBoxTitle"], ["children", "35 " + t("sajab.home.year")]]), createElement("p", createObj(Helpers_combineClasses("", props_6)))), (props_8 = ofArray([["className", "sajabHomeBoxSubTitle"], ["children", t("sajab.home.text2")]]), createElement("p", createObj(Helpers_combineClasses("", props_8))))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (elms_1 = ofArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "sajabHomeBoxTitle"], ["children", "250+"]])))), (props_13 = ofArray([["className", "sajabHomeBoxSubTitle"], ["children", t("sajab.home.text3")]]), createElement("p", createObj(Helpers_combineClasses("", props_13))))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_1)),
    })), (elms_2 = ofArray([createElement("p", createObj(Helpers_combineClasses("", ofArray([["className", "sajabHomeBoxTitle"], ["children", "80.000+"]])))), (props_18 = ofArray([["className", "sajabHomeBoxSubTitle"], ["children", t("sajab.home.text4")]]), createElement("p", createObj(Helpers_combineClasses("", props_18))))]), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_2)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_21))))])]]), createElement("div", createObj(Helpers_combineClasses("block", props_23)))), (props_29 = ofArray([["className", join(" ", ["home-box-container", "is-mobile", "is-fluid", "home-box-height", "second-image-sajab", "textShadow", "homeBox2"])], ["style", {
        backgroundImage: ("url(\u0027" + Img_homeBackgroundSajab) + "\u0027)",
    }], ["children", Interop_reactApi.Children.toArray([(props_25 = ofArray([["className", "sajabHomeBoxTitle"], ["style", {
        paddingBottom: 20,
    }], ["children", t("sajab.home.image2.title")]]), createElement("p", createObj(Helpers_combineClasses("", props_25)))), (props_27 = ofArray([["className", "homeBox2Text"], ["children", t("sajab.home.image2.subtitle")]]), createElement("p", createObj(Helpers_combineClasses("", props_27))))])]]), createElement("div", createObj(Helpers_combineClasses("container", props_29)))), (props_50 = ofArray([["className", "has-background-white"], ["className", "py-6"], ["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["style", {
        fontSize: 30 + "px",
        paddingBottom: 20,
        fontWeight: "bold",
        textAlign: "center",
    }], ["children", t("sajab.home.recommendation.recommendation")]]), createElement("p", createObj(Helpers_combineClasses("", props_31)))), (elms_7 = ofArray([(elms_4 = singleton((elms_3 = ofArray([(s = t("sajab.home.recommendation.user1.text"), createElement("p", {
        className: "",
        children: s,
    })), createElement("br", {}), (s_1 = t("sajab.home.recommendation.user1.name"), createElement("p", {
        className: "",
        children: s_1,
    })), createElement("br", {}), (s_2 = tWithArgs("sajab.home.recommendation.user1.ask", {
        name: "Gunnar",
    }), createElement("p", {
        className: "",
        children: s_2,
    })), createElement("p", {
        className: "",
        children: "0703 455 618",
    })]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_3)),
    }))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_4)),
    })), (elms_6 = singleton((elms_5 = ofArray([(s_4 = t("sajab.home.recommendation.user2.text"), createElement("p", {
        className: "",
        children: s_4,
    })), createElement("br", {}), (s_5 = t("sajab.home.recommendation.user2.name"), createElement("p", {
        className: "",
        children: s_5,
    })), createElement("br", {}), (s_6 = tWithArgs("sajab.home.recommendation.user1.ask", {
        name: "Sune",
    }), createElement("p", {
        className: "",
        children: s_6,
    })), createElement("p", {
        className: "",
        children: "0702233844",
    })]), createElement("div", {
        className: "box",
        children: Interop_reactApi.Children.toArray(Array.from(elms_5)),
    }))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms_6)),
    }))]), createElement("div", {
        className: "columns",
        children: Interop_reactApi.Children.toArray(Array.from(elms_7)),
    }))])]]), createElement("div", createObj(Helpers_combineClasses("container", props_50)))), (props_56 = ofArray([["className", join(" ", ["paddingLeftRight-mobile45", "paddingBottom-desktop80-mobile10"])], ["style", {
        backgroundColor: "#efefef",
        textAlign: "center",
    }], ["children", Interop_reactApi.Children.toArray([createElement("h2", {
        className: join(" ", ["paddingTop-desktop80-mobile10", "home-info-white-space-title"]),
        children: t("sajab.home.follow"),
    }), createElement("div", {
        className: join(" ", ["is-centered"]),
        style: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20,
        },
        children: Interop_reactApi.Children.toArray([createElement("a", {
            className: join(" ", ["socialHome", "icon", "fab", "fa-facebook-f", "fa-2x", "home-social-media-icon"]),
            href: set$_1.FacebookLink,
        }), createElement("a", {
            className: join(" ", ["socialHome", "icon", "fab", "fa-instagram", "fa-2x", "home-social-media-icon"]),
            href: set$_1.InstagramLink,
        })]),
    })])]]), createElement("div", createObj(Helpers_combineClasses("box", props_56))))]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
    });
}

