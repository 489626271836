import { t } from "../Localization.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { createElement } from "react";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { singleton as singleton_1, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { useFeliz_React__React_useDeferred_Static_2344FC52 } from "../fable_modules/Feliz.UseDeferred.1.4.1/UseDeferred.fs.js";
import { homeApi } from "../Communication.js";
import { large } from "../Components/Loader.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Img_joakimSajab, Img_jesperSajab, Img_mickeSajab, Img_andersSajab, Img_rolfSajab, Img_manneSajab, Img_martenSajab, Img_stigSajab, Img_grayJean, Img_aboutUsBackgroundSajab } from "../Css.js";
import { ScheduleSajab } from "./SajabFooter.js";

export function p(s) {
    return t("contactPage." + s);
}

export function contactItem(name, position, image, email, phone, subtitle) {
    const props_9 = ofArray([["className", "is-half-mobile"], ["className", "is-half-tablet"], ["className", "is-one-quarter-desktop"], ["children", Interop_reactApi.Children.toArray([createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            className: "contact-green-border",
            children: Interop_reactApi.Children.toArray([createElement("div", {
                style: {
                    backgroundImage: ("url(\u0027" + image) + "\u0027)",
                },
                className: "contact-img-worker",
            })]),
        }), createElement("div", {
            className: "contact-info-background",
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement("h2", {
                children: name,
                className: "contact-name",
            })), delay(() => append(singleton(createElement("p", {
                children: position,
                className: "contact-info",
            })), delay(() => append((phone !== "") ? singleton(createElement("p", {
                children: phone,
                className: "contact-info",
            })) : empty(), delay(() => append((email !== "") ? singleton(createElement("p", {
                children: email,
                className: "contact-info",
            })) : empty(), delay(() => ((subtitle !== "") ? singleton(createElement("p", {
                children: subtitle,
                className: "contact-info",
            })) : empty()))))))))))))),
        })]),
    })])]]);
    return createElement("div", createObj(Helpers_combineClasses("column", props_9)));
}

export function Render() {
    let props_1, props_25, props_17, value_28, value_29, value_34, value_35, value_36, props_23, props_34, props_28, props_30, props_32;
    const settings = useFeliz_React__React_useDeferred_Static_2344FC52(homeApi().getCompanyInfo(), []);
    switch (settings.tag) {
        case 1: {
            return large;
        }
        case 3: {
            return settings.fields[0].message;
        }
        case 2: {
            const set$ = settings.fields[0];
            return createElement("div", {
                children: Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["contact-img-top"]),
                    style: {
                        backgroundImage: ("url(\u0027" + Img_aboutUsBackgroundSajab) + "\u0027)",
                        display: "flex",
                        alignItems: "center",
                    },
                    children: Interop_reactApi.Children.toArray([(props_1 = ofArray([["className", join(" ", ["is-size-3-mobile", "is-size-1", "contactText"])], ["children", Interop_reactApi.Children.toArray(["SAJAB", createElement("br", {}), t("sajab.auctioners")])]]), createElement("div", createObj(Helpers_combineClasses("column", props_1))))]),
                }), createElement("div", {
                    className: join(" ", ["contact-info-box"]),
                    children: Interop_reactApi.Children.toArray([(props_25 = ofArray([["style", {
                        margin: 0,
                        justifyContent: "space-between",
                    }], ["children", Interop_reactApi.Children.toArray([(props_17 = ofArray([["className", "is-5-fullhd"], ["className", "is-7-desktop"], ["className", "is-6-tablet"], ["className", join(" ", ["contact-info-text-on-white"])], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                        style: {
                            whiteSpace: "pre-line",
                        },
                        children: t("sajab.aboutUs.text1"),
                    }), createElement("br", {}), (value_28 = t("sajab.aboutUs.text2"), createElement("p", {
                        children: [value_28],
                    })), createElement("br", {}), (value_29 = t("sajab.aboutUs.text3"), createElement("p", {
                        children: [value_29],
                    })), createElement("br", {}), createElement("p", {
                        style: {
                            fontSize: 22 + "px",
                        },
                        children: t("sajab.aboutUs.title"),
                    }), createElement("br", {}), (value_34 = t("sajab.aboutUs.text4"), createElement("p", {
                        children: [value_34],
                    })), createElement("br", {}), (value_35 = t("sajab.aboutUs.text5"), createElement("p", {
                        children: [value_35],
                    })), createElement("br", {}), (value_36 = t("sajab.aboutUs.text6"), createElement("p", {
                        children: [value_36],
                    }))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_17)))), (props_23 = singleton_1(["children", Interop_reactApi.Children.toArray([createElement("div", {
                        className: join(" ", ["contact-info-text-on-grey", "contactGreyBox"]),
                        children: Interop_reactApi.Children.toArray([set$.Phone, createElement("br", {}), set$.Email, createElement("br", {}), set$.Address, createElement("br", {}), createElement(ScheduleSajab, null)]),
                    })])]), createElement("div", createObj(Helpers_combineClasses("column", props_23))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_25))))]),
                }), createElement("div", {
                    style: {
                        backgroundImage: ("url(\u0027" + Img_grayJean) + "\u0027)",
                        padding: ((((30 + "px ") + 0) + "px ") + 100) + "px",
                    },
                    children: Interop_reactApi.Children.toArray([(props_34 = singleton_1(["children", Interop_reactApi.Children.toArray([(props_28 = ofArray([["className", "is-mobile"], ["className", "is-centered"], ["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray([contactItem("Stig-Arne Johansson", t("sajab.staff.position.one"), Img_stigSajab, "stigarne@sajab.se ", "0141-21 68 46", ""), contactItem("Mårten Thörner", t("sajab.staff.position.two"), Img_martenSajab, "marten@sajab.se", "0141-21 69 81", ""), contactItem("Manne Sjöberg", t("sajab.staff.position.three"), Img_manneSajab, "manne@sajab.se", "0141-21 69 58", "")])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_28)))), (props_30 = ofArray([["className", "is-centered"], ["className", "is-mobile"], ["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray([contactItem("Rolf Johansson", t("sajab.staff.position.four"), Img_rolfSajab, "", "", ""), contactItem("Anders Isaksson", t("sajab.staff.position.five"), Img_andersSajab, "anders@sajab.se", "070-621 09 45", "")])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_30)))), (props_32 = ofArray([["className", "is-centered"], ["className", "is-mobile"], ["className", "is-multiline"], ["children", Interop_reactApi.Children.toArray([contactItem("Mikael Almroth", t("sajab.staff.position.seven"), Img_mickeSajab, "micke@sajab.se", "", ""), contactItem("Jesper Almroth", t("sajab.staff.position.seven"), Img_jesperSajab, "jesper@sajab.se", "", ""), contactItem("Joakim Almroth", t("sajab.staff.position.seven"), Img_joakimSajab, "jocke@sajab.se", "", "")])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_32))))])]), createElement("div", createObj(Helpers_combineClasses("container", props_34))))]),
                })]),
            });
        }
        default: {
            return large;
        }
    }
}

