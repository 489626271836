import { tWithArgs, t } from "../Localization.js";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.1.45.0/React.fs.js";
import { Resource$1 } from "../Shared/Shared.js";
import { Deferred$1 } from "../Extensions.js";
import { startImmediate } from "../fable_modules/fable-library.3.6.1/Async.js";
import { singleton } from "../fable_modules/fable-library.3.6.1/AsyncBuilder.js";
import { homeApi } from "../Communication.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Img_greyBackground } from "../Css.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { collect, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.6.1/Seq.js";
import { Route, toPath } from "../Router.js";
import { indexed, length, head, singleton as singleton_2, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { scrollableAnchor } from "../Components/ScrollableAnchor.js";
import { medium } from "../Components/Loader.js";

export function p(key) {
    return t("sajab.buy." + key);
}

export function View(viewInputProps) {
    let props_62, props_10, props_16, props_21, props_26, props_31, props_36, props_43, props_50, props_55, props_60, props_70;
    const user = viewInputProps.user;
    const patternInput = useFeliz_React__React_useState_Static_1505(new Resource$1(0));
    const lastAuction = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505("");
    const symbol = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(new Deferred$1(0));
    const intervals = patternInput_2[0];
    useReact_useEffectOnce_3A5B6456(() => {
        startImmediate(singleton.Delay(() => singleton.Bind(homeApi().getNextOrLastFinishedAuction(), (_arg1) => {
            patternInput[1](new Resource$1(1, _arg1));
            return singleton.Bind(homeApi().getBiddingIntervals(), (_arg2) => singleton.Bind(homeApi().getCurrencySettings(), (_arg3) => {
                patternInput_2[1](new Deferred$1(2, _arg2));
                patternInput_1[1](_arg3.CurrencySymbol);
                return singleton.Return();
            }));
        })));
    });
    return createElement("div", {
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                display: "flex",
                justifyContent: "center",
                backgroundImage: ("url(\u0027" + Img_greyBackground) + "\u0027)",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: join(" ", ["buy-box"]),
                children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("h1", {
                    className: join(" ", ["buy-text-h1"]),
                    children: p("head.title"),
                })), delay(() => append(singleton_1(createElement("h2", {
                    children: p("head.subtitle.one"),
                    className: join(" ", ["buy-text-h2"]),
                })), delay(() => append(singleton_1(createElement("h2", {
                    children: p("head.subtitle.two"),
                    className: join(" ", ["buy-text-h2"]),
                })), delay(() => {
                    let props_5, props_3;
                    return (user.tag === 1) ? singleton_1((props_5 = ofArray([["className", join(" ", ["buy-box-button"])], ["className", "is-primary"], ["href", toPath(new Route(11))], ["children", p("button.profile")]]), createElement("a", createObj(Helpers_combineClasses("button", props_5))))) : singleton_1((props_3 = ofArray([["className", join(" ", ["buy-box-button"])], ["className", "is-primary"], ["href", toPath(new Route(10))], ["children", p("head.button.createAccount")]]), createElement("a", createObj(Helpers_combineClasses("button", props_3)))));
                })))))))))),
            })]),
        }), createElement("div", {
            className: join(" ", ["buy-container-box"]),
            style: {
                whiteSpace: "pre-line",
            },
            children: Interop_reactApi.Children.toArray([createElement("div", {
                className: "is-vcentered",
                className: join(" ", ["buy-content-box"]),
                children: Interop_reactApi.Children.toArray([(props_62 = ofArray([["className", "is-9"], ["className", "is-6-fullhd"], ["className", "is-6-widescreen"], ["className", "is-9-desktop"], ["className", "is-11-tablet"], ["className", join(" ", ["buy-left-column-block"])], ["children", Interop_reactApi.Children.toArray([(props_10 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("p", {
                    className: join(" ", ["buy-text-box", "buy-subtitle"]),
                    children: p("anchor.title"),
                })])]), createElement("div", createObj(Helpers_combineClasses("container", props_10)))), (props_16 = ofArray([["style", {
                    paddingLeft: 30,
                    marginTop: 35,
                }], ["className", join(" ", ["buttons"])], ["children", Interop_reactApi.Children.toArray([Interop_reactApi.createElement(scrollableAnchor, {
                    className: join(" ", ["button", "buy-button-small"]),
                    children: p("anchor.koparprovision"),
                    href: "#koparprovision",
                }), Interop_reactApi.createElement(scrollableAnchor, {
                    className: join(" ", ["button", "buy-button-small"]),
                    children: p("anchor.visning"),
                    href: "#visning",
                }), Interop_reactApi.createElement(scrollableAnchor, {
                    className: join(" ", ["button", "buy-button-small"]),
                    children: p("anchor.frakt"),
                    href: "#frakt",
                }), Interop_reactApi.createElement(scrollableAnchor, {
                    className: join(" ", ["button", "buy-button-small"]),
                    children: p("anchor.budgivning"),
                    href: "#budgivning",
                })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_16)))), (props_21 = ofArray([["className", join(" ", ["buy-text-box"])], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    children: p("title.1"),
                    className: "buy-title",
                }), createElement("p", {
                    children: p("subtitle.1"),
                    className: "buy-subtitle",
                }), createElement("p", {
                    children: p("text.1"),
                    className: "buy-text",
                })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_21)))), (props_26 = ofArray([["className", "buy-content-background"], ["id", "koparprovision"], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    children: p("title.2"),
                    className: "buy-title",
                }), createElement("p", {
                    children: p("subtitle.2"),
                    className: "buy-subtitle",
                }), createElement("span", {
                    children: p("text.2"),
                    className: "buy-text",
                })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_26)))), (props_31 = ofArray([["className", "buy-text-box"], ["id", "visning"], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    children: p("title.3"),
                    className: "buy-title",
                }), createElement("p", {
                    children: p("subtitle.3"),
                    className: "buy-subtitle",
                }), createElement("p", {
                    children: p("text.3"),
                    className: "buy-text",
                })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_31)))), (props_36 = ofArray([["className", "buy-content-background"], ["id", "budgivning"], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    children: p("title.4"),
                    className: "buy-title",
                }), createElement("p", {
                    children: p("subtitle.4"),
                    className: "buy-subtitle",
                }), createElement("p", {
                    children: p("text.4"),
                    className: "buy-text",
                })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_36)))), (props_43 = ofArray([["className", "buy-text-box"], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    children: p("title.5"),
                    className: "buy-title",
                }), createElement("p", {
                    children: p("subtitle.5"),
                    className: "buy-subtitle",
                }), createElement("a", createObj(toList(delay(() => {
                    let matchValue;
                    return append((lastAuction.tag === 1) ? ((matchValue = lastAuction.fields[0].Status, (matchValue.tag === 0) ? singleton_1(["href", toPath(new Route(0))]) : ((matchValue.tag === 2) ? singleton_1(["href", toPath(new Route(0))]) : singleton_1(["href", toPath(new Route(2, void 0))])))) : singleton_1(["className", "is-loading"]), delay(() => {
                        let props_40;
                        return singleton_1(["children", Interop_reactApi.Children.toArray([(props_40 = ofArray([["className", join(" ", ["buy-button-big"])], ["className", "is-primary"], ["children", p("button.läs.mer.här")]]), createElement("button", createObj(Helpers_combineClasses("button", props_40))))])]);
                    }));
                }))))])]]), createElement("div", createObj(Helpers_combineClasses("container", props_43)))), (props_50 = ofArray([["className", "buy-content-background"], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    children: p("title.6"),
                    className: "buy-title",
                }), createElement("p", {
                    children: p("subtitle.6.1"),
                    className: "buy-subtitle",
                }), createElement("p", {
                    children: p("text.6.1"),
                    className: "buy-text",
                }), createElement("p", {
                    children: p("subtitle.6.2"),
                    className: "buy-subtitle",
                }), createElement("p", {
                    children: p("text.6.2"),
                    className: "buy-text",
                })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_50)))), (props_55 = ofArray([["className", "buy-text-box"], ["id", "frakt"], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    children: p("title.7"),
                    className: "buy-title",
                }), createElement("p", {
                    children: p("subtitle.7.4"),
                    className: "buy-subtitle",
                }), createElement("p", {
                    children: p("text.7.4"),
                    className: "buy-text",
                })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_55)))), (props_60 = ofArray([["className", "buy-content-background"], ["children", Interop_reactApi.Children.toArray([createElement("p", {
                    children: p("title.8"),
                    className: "buy-title",
                }), createElement("p", {
                    children: p("subtitle.8"),
                    className: "buy-subtitle",
                }), createElement("p", {
                    children: p("text.8"),
                    className: "buy-text",
                })])]]), createElement("div", createObj(Helpers_combineClasses("container", props_60))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_62)))), (props_70 = singleton_2(["children", Interop_reactApi.Children.toArray([createElement("div", {
                    className: join(" ", ["buy-content-container-green", "buyContainerSajab"]),
                    children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton_1(createElement("p", {
                        children: p("box.title"),
                        className: join(" ", ["is-size-3-mobile", "is-size-2", "buyBorder"]),
                        style: {
                            marginBottom: 10,
                            paddingBottom: 15,
                            borderWidth: 3,
                            borderBottomStyle: "solid",
                            fontFamily: "Abril Fatface, cursive",
                        },
                    })), delay(() => {
                        switch (intervals.tag) {
                            case 1: {
                                return singleton_1(medium("Loading"));
                            }
                            case 2: {
                                const intervals_2 = intervals.fields[0];
                                return append(singleton_1(createElement("p", createObj(toList(delay(() => append(singleton_1(["children", tWithArgs("buyPage.box.subtitle", {
                                    bid: `${head(intervals_2).From} ${symbol}`,
                                })]), delay(() => singleton_1(["className", join(" ", ["buy-container-green-h2"])])))))))), delay(() => {
                                    let children;
                                    return singleton_1((children = toList(delay(() => collect((matchValue_1) => {
                                        const interval = matchValue_1[1];
                                        return singleton_1(createElement("li", {
                                            className: join(" ", ["buy-table-list", "buyBorder"]),
                                            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append((matchValue_1[0] !== (length(intervals_2) - 1)) ? singleton_1(`${interval.From} ${symbol} - ${interval.To} ${symbol}`) : singleton_1(`${interval.From} ${symbol} - `), delay(() => singleton_1(createElement("span", {
                                                children: `${interval.BidSize} ${symbol}`,
                                                style: {
                                                    float: "right",
                                                },
                                            })))))))),
                                        }));
                                    }, indexed(intervals_2)))), createElement("ul", {
                                        children: Interop_reactApi.Children.toArray(Array.from(children)),
                                    })));
                                }));
                            }
                            default: {
                                return singleton_1(medium("Loading"));
                            }
                        }
                    })))))),
                })])]), createElement("div", createObj(Helpers_combineClasses("column", props_70))))]),
            })]),
        })]),
    });
}

