import { createElement } from "react";
import { Img_agricultureBackground2, Img_rolfSajab, Img_stigSajab, Img_agricultureBackground1 } from "../Css.js";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { t } from "../Localization.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { Route, toPath } from "../Router.js";

export function Render() {
    let props_8, props, props_3, props_6, props_38, props_25, props_11, props_13, props_16, props_18, props_21, props_23, props_36, props_27, props_79, props_48, props_40, props_43, props_46, props_61, props_50, props_53, props_56, props_59, props_77, props_63, props_66, props_69, props_72, props_75, props_106, elms, props_85, props_81, props_83, props_104, props_88, props_91, props_93, props_98, props_100;
    const children_1 = ofArray([createElement("div", {
        style: {
            backgroundImage: ("url(\u0027" + Img_agricultureBackground1) + "\u0027)",
        },
        className: "agricultureBox1",
        children: Interop_reactApi.Children.toArray([(props_8 = ofArray([["className", "is-6"], ["style", {
            alignSelf: "center",
        }], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
            fontSize: 40 + "px",
        }], ["className", "has-text-weight-bold"], ["className", "has-text-white"], ["children", t("sajab.agriculture.title")]]), createElement("p", createObj(Helpers_combineClasses("", props)))), createElement("br", {}), (props_3 = ofArray([["style", {
            fontSize: 18 + "px",
        }], ["className", "has-text-white"], ["children", t("sajab.agriculture.subtitle")]]), createElement("p", createObj(Helpers_combineClasses("", props_3)))), createElement("br", {}), (props_6 = ofArray([["className", join(" ", ["is-primary", "is-uppercase", "has-text-weight-bold"])], ["children", t("sajab.agriculture.button")], ["href", toPath(new Route(1))]]), createElement("a", createObj(Helpers_combineClasses("button", props_6))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_8))))]),
    }), (props_38 = ofArray([["className", "py-6"], ["className", "vintageMarginsBox"], ["children", Interop_reactApi.Children.toArray([(props_25 = ofArray([["className", "is-7"], ["children", Interop_reactApi.Children.toArray([(props_11 = ofArray([["className", "has-text-weight-bold"], ["style", {
        fontSize: 20 + "px",
    }], ["children", t("sajab.agriculture.block1.title1")]]), createElement("p", createObj(Helpers_combineClasses("", props_11)))), (props_13 = singleton(["children", t("sajab.agriculture.block1.subtitle1")]), createElement("p", createObj(Helpers_combineClasses("", props_13)))), createElement("br", {}), (props_16 = ofArray([["style", {
        fontSize: 20 + "px",
    }], ["className", "has-text-weight-bold"], ["children", t("sajab.agriculture.block1.title2")]]), createElement("p", createObj(Helpers_combineClasses("", props_16)))), (props_18 = singleton(["children", t("sajab.agriculture.block1.subtitle2")]), createElement("p", createObj(Helpers_combineClasses("", props_18)))), createElement("br", {}), (props_21 = ofArray([["style", {
        fontSize: 20 + "px",
    }], ["className", "has-text-weight-bold"], ["children", t("sajab.agriculture.block1.title3")]]), createElement("p", createObj(Helpers_combineClasses("", props_21)))), (props_23 = singleton(["children", t("sajab.agriculture.block1.subtitle3")]), createElement("p", createObj(Helpers_combineClasses("", props_23))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_25)))), (props_36 = ofArray([["className", "has-background-primary"], ["className", "is-5"], ["style", {
        padding: 40,
    }], ["children", Interop_reactApi.Children.toArray([(props_27 = ofArray([["className", "has-text-weight-bold"], ["style", {
        fontSize: 30 + "px",
    }], ["children", t("sajab.agriculture.block2.title")]]), createElement("p", createObj(Helpers_combineClasses("", props_27)))), createElement("br", {}), createElement("p", createObj(Helpers_combineClasses("", singleton(["children", "Kontakta Stig-Arne Johansson"])))), createElement("p", createObj(Helpers_combineClasses("", singleton(["children", "0141-21 68 46 "])))), createElement("p", createObj(Helpers_combineClasses("", singleton(["children", "info@sajab.se"]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_36))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_38)))), (props_79 = ofArray([["className", "pb-6"], ["className", "vintageMarginsBox"], ["children", Interop_reactApi.Children.toArray([(props_48 = ofArray([["className", join(" ", ["vintageMarginColumns"])], ["style", {
        padding: 40,
        borderRadius: 5,
    }], ["children", Interop_reactApi.Children.toArray([(props_40 = ofArray([["style", {
        fontSize: 20 + "px",
    }], ["className", "has-text-weight-bold"], ["children", t("sajab.agriculture.block3.title")]]), createElement("p", createObj(Helpers_combineClasses("", props_40)))), createElement("br", {}), (props_43 = singleton(["children", t("sajab.agriculture.block3.text1")]), createElement("p", createObj(Helpers_combineClasses("", props_43)))), createElement("br", {}), (props_46 = singleton(["children", t("sajab.agriculture.block3.text2")]), createElement("p", createObj(Helpers_combineClasses("", props_46))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_48)))), (props_61 = ofArray([["className", join(" ", ["vintageMarginColumns"])], ["style", {
        padding: 40,
        borderRadius: 5,
    }], ["children", Interop_reactApi.Children.toArray([(props_50 = ofArray([["style", {
        fontSize: 20 + "px",
    }], ["className", "has-text-weight-bold"], ["children", t("sajab.agriculture.block4.title")]]), createElement("p", createObj(Helpers_combineClasses("", props_50)))), createElement("br", {}), (props_53 = singleton(["children", t("sajab.agriculture.block4.text1")]), createElement("p", createObj(Helpers_combineClasses("", props_53)))), createElement("br", {}), (props_56 = singleton(["children", t("sajab.agriculture.block4.text2")]), createElement("p", createObj(Helpers_combineClasses("", props_56)))), createElement("br", {}), (props_59 = singleton(["children", t("sajab.agriculture.block4.text3")]), createElement("p", createObj(Helpers_combineClasses("", props_59))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_61)))), (props_77 = ofArray([["style", {
        padding: 40,
        borderRadius: 5,
    }], ["children", Interop_reactApi.Children.toArray([(props_63 = ofArray([["style", {
        fontSize: 20 + "px",
    }], ["className", "has-text-weight-bold"], ["children", t("sajab.agriculture.block5.title")]]), createElement("p", createObj(Helpers_combineClasses("", props_63)))), createElement("br", {}), (props_66 = singleton(["children", t("sajab.agriculture.block5.text1")]), createElement("p", createObj(Helpers_combineClasses("", props_66)))), createElement("br", {}), (props_69 = singleton(["children", t("sajab.agriculture.block5.text2")]), createElement("p", createObj(Helpers_combineClasses("", props_69)))), createElement("br", {}), (props_72 = singleton(["children", t("sajab.agriculture.block5.text3")]), createElement("p", createObj(Helpers_combineClasses("", props_72)))), createElement("br", {}), (props_75 = singleton(["children", t("sajab.agriculture.block5.text4")]), createElement("p", createObj(Helpers_combineClasses("", props_75))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_77))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_79)))), (props_106 = ofArray([["className", "mb-6"], ["className", join(" ", ["vintageMarginsBox"])], ["children", Interop_reactApi.Children.toArray([(elms = singleton((props_85 = ofArray([["className", "is-mobile"], ["className", "agricultureBox2"], ["children", Interop_reactApi.Children.toArray([(props_81 = singleton(["style", {
        backgroundImage: ("url(\u0027" + Img_stigSajab) + "\u0027)",
        backgroundSize: "cover",
        margin: ((((((10 + "px ") + 5) + "px ") + 10) + "px ") + 10) + "px",
    }]), createElement("div", createObj(Helpers_combineClasses("column", props_81)))), (props_83 = singleton(["style", {
        backgroundImage: ("url(\u0027" + Img_rolfSajab) + "\u0027)",
        backgroundSize: "cover",
        margin: ((((((10 + "px ") + 10) + "px ") + 10) + "px ") + 5) + "px",
    }]), createElement("div", createObj(Helpers_combineClasses("column", props_83))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_85))))), createElement("div", {
        className: "column",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    })), (props_104 = ofArray([["style", {
        padding: 40,
        alignSelf: "center",
    }], ["children", Interop_reactApi.Children.toArray([(props_88 = ofArray([["style", {
        fontSize: 40 + "px",
    }], ["className", "has-text-weight-bold"], ["children", t("sajab.agriculture.block6.title")]]), createElement("p", createObj(Helpers_combineClasses("", props_88)))), createElement("br", {}), (props_91 = ofArray([["style", {
        fontSize: 20 + "px",
    }], ["children", "Stig-Arne Johansson (t.v.)"]]), createElement("p", createObj(Helpers_combineClasses("", props_91)))), (props_93 = singleton(["children", t("sajab.agriculture.block6.position1")]), createElement("p", createObj(Helpers_combineClasses("", props_93)))), createElement("p", createObj(Helpers_combineClasses("", singleton(["children", "0141-21 68 46"])))), createElement("br", {}), (props_98 = ofArray([["style", {
        fontSize: 20 + "px",
    }], ["children", "Rolf Johansson (t.h.)"]]), createElement("p", createObj(Helpers_combineClasses("", props_98)))), (props_100 = singleton(["children", t("sajab.agriculture.block6.position2")]), createElement("p", createObj(Helpers_combineClasses("", props_100)))), createElement("p", createObj(Helpers_combineClasses("", singleton(["children", "0141-21 69 58"]))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_104))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_106)))), createElement("img", {
        src: Img_agricultureBackground2,
        className: "agricultureImage",
    })]);
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_1)),
    });
}

