import { t } from "../Localization.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.1.45.0/Interop.fs.js";
import { Img_multisellerSajab } from "../Css.js";
import { singleton, ofArray } from "../fable_modules/fable-library.3.6.1/List.js";
import { createObj } from "../fable_modules/fable-library.3.6.1/Util.js";
import { Helpers_combineClasses } from "../fable_modules/Feliz.Bulma.2.15.0/ElementBuilders.fs.js";
import { scrollableAnchor } from "../Components/ScrollableAnchor.js";
import { join } from "../fable_modules/fable-library.3.6.1/String.js";
import { View as View_1 } from "./AppointmentBookingSajab.js";

export function p(key) {
    return t("sajab.multisellerAuction." + key);
}

export function View(viewInputProps) {
    let props_7, props, props_3, props_24, props_15, props_10, props_13, props_22, props_17, props_20, props_33, props_31, props_26, props_29, props_38, props_36;
    const user = viewInputProps.user;
    return createElement("div", {
        style: {
            whiteSpace: "pre-line",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            style: {
                backgroundImage: ("url(\u0027" + Img_multisellerSajab) + "\u0027)",
                backgroundColor: "transparent",
            },
            className: "agricultureBox1",
            children: Interop_reactApi.Children.toArray([(props_7 = ofArray([["className", "is-6"], ["style", {
                alignSelf: "center",
            }], ["children", Interop_reactApi.Children.toArray([(props = ofArray([["style", {
                fontSize: 40 + "px",
            }], ["className", "has-text-weight-bold"], ["className", "has-text-white"], ["children", p("title")]]), createElement("p", createObj(Helpers_combineClasses("", props)))), createElement("br", {}), (props_3 = ofArray([["style", {
                fontSize: 18 + "px",
            }], ["className", "has-text-white"], ["children", p("subtitle")]]), createElement("p", createObj(Helpers_combineClasses("", props_3)))), createElement("br", {}), Interop_reactApi.createElement(scrollableAnchor, {
                className: join(" ", ["button", "buy-button-small"]),
                children: p("button"),
                href: "#box",
            })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_7))))]),
        }), (props_24 = ofArray([["className", "mb-3"], ["className", "vintageMarginsBox"], ["children", Interop_reactApi.Children.toArray([(props_15 = ofArray([["className", join(" ", ["vintageMarginColumns"])], ["style", {
            padding: 40,
        }], ["children", Interop_reactApi.Children.toArray([(props_10 = ofArray([["style", {
            fontSize: 20 + "px",
        }], ["className", "has-text-weight-bold"], ["children", p("box1.title")]]), createElement("p", createObj(Helpers_combineClasses("", props_10)))), createElement("br", {}), (props_13 = singleton(["children", p("box1.text")]), createElement("p", createObj(Helpers_combineClasses("", props_13))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_15)))), (props_22 = ofArray([["className", join(" ", ["vintageMarginColumns"])], ["style", {
            padding: 40,
        }], ["children", Interop_reactApi.Children.toArray([(props_17 = ofArray([["style", {
            fontSize: 20 + "px",
        }], ["className", "has-text-weight-bold"], ["children", p("box2.title")]]), createElement("p", createObj(Helpers_combineClasses("", props_17)))), createElement("br", {}), (props_20 = singleton(["children", p("box2.text")]), createElement("p", createObj(Helpers_combineClasses("", props_20))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_22))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_24)))), (props_33 = ofArray([["className", "mb-3"], ["className", join(" ", ["vintageMarginsBox", "backgroundGrayLight"])], ["children", Interop_reactApi.Children.toArray([(props_31 = ofArray([["className", join(" ", ["vintageMarginColumns"])], ["children", Interop_reactApi.Children.toArray([(props_26 = ofArray([["style", {
            fontSize: 20 + "px",
        }], ["className", "has-text-weight-bold"], ["children", p("box3.title")]]), createElement("p", createObj(Helpers_combineClasses("", props_26)))), createElement("br", {}), (props_29 = singleton(["children", p("box3.text")]), createElement("p", createObj(Helpers_combineClasses("", props_29))))])]]), createElement("div", createObj(Helpers_combineClasses("column", props_31))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_33)))), (props_38 = ofArray([["id", "box"], ["className", "vintageMarginsBox"], ["children", Interop_reactApi.Children.toArray([(props_36 = ofArray([["className", "is-9"], ["className", "is-8-fullhd"], ["className", "is-8-widescreen"], ["className", "is-9-desktop"], ["className", "is-11-tablet"], ["style", {
            padding: 40,
        }], ["children", Interop_reactApi.Children.toArray([createElement("fieldset", {
            children: Interop_reactApi.Children.toArray([createElement(View_1, {
                user: user,
                isSeparatePage: false,
            })]),
        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_36))))])]]), createElement("div", createObj(Helpers_combineClasses("columns", props_38))))]),
    });
}

